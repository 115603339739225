import AppColors from './AppColors';
import media from './media';

const StyledComponentsTheme = {
    color: AppColors,
    layout: {
        maxWidth: '944px',
        maxWidthLanding: '1440px',
    },
    breakpoint: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
    ...media,
}

export default StyledComponentsTheme;
