export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const MY_PAGE = '/mypage';
export const HOME = '/';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const MENU = '/weeklymenu';
export const ORDER_COMPLETE = '/order/complete';

export const ORDER_STEP1 = '/order/entrance';
export const ORDER_STEP2 = '/order/select';
export const ORDER_STEP3 = '/order/info2';