import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";

import HomeLayout from "./page/home/HomeLayout";
import SignUpScreen from "./page/login/sign_up";
import SignUpPage from "./page/login/signUp";
import SignInPage from "./page/login/signIn";
import MyPageScreen2 from "./page/login/myPage2";

// import OrderCompleteScreen from './page/order/order_complete';
// import SignInMobile from './page/login/mobile/signIn';
// import SignUpMobile from './page/login/mobile/signUp';
// import MyPageMenu from './page/login/mobile/mypage_menu';
// import MobileAccountBox from './page/login/mobile/account_box';
// import MobileOrderInfoBox from './page/login/mobile/orderinfo_box';
import MenuLayout from "./page/menu";

// import OrderEntrancePage from './page/order/order_entrance_page';
// import OrderSelectPage from './page/order/order_select_page';
// import OrderInfoPage from './page/order/order_info_page';
// import GroupOrderPage from './page/order/group_order_page';

import BillPage from "./page/bill/index";
import Bill_S_Page from "./page/bill/bill2";
import ContactPage from "./page/contact";

import * as ROUTES from "../src/constants/routes";
import withAuthentication from "./components/utils/withAuthentication";

class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" render={() => <HomeLayout />} />
          <Route exact path="/weeklymenu" render={() => <MenuLayout />} />
          {/* <Route exact path={ROUTES.ORDER_COMPLETE} render={() => <OrderCompleteScreen />} /> */}
          <Route exact path="/login/sign_up" render={() => <SignUpScreen />} />
          <Route exact path={ROUTES.SIGN_UP} render={() => <SignUpPage />} />
          <Route exact path={ROUTES.SIGN_IN} render={() => <SignInPage />} />
          <Route exact path={ROUTES.MY_PAGE} render={() => <MyPageScreen2 />} />
          {/* <Route exact path="/mobile/sign_in" render={() => <SignInMobile />} />
          <Route exact path="/mobile/sign_up" render={() => <SignUpMobile />} />
          <Route exact path="/mobile/mypage" render={() => <MyPageMenu />} />
          <Route exact path="/mobile/account" render={() => <MobileAccountBox />} />
          <Route exact path="/mobile/order_info" render={() => <MobileOrderInfoBox />} />

          <Route exact path="/order/entrance" render={() => <OrderEntrancePage />} />
          <Route exact path="/order/select" render={() => <OrderSelectPage />} />
          <Route exact path="/order/info2" render={() => <OrderInfoPage />} />
          <Route exact path="/order/group" render={() => <GroupOrderPage />} /> */}

          <Route exact path="/bill/jeungsanmiddle" render={() => <BillPage />} />
          <Route
            exact
            path="/bill/youthunion2"
            render={() => <Bill_S_Page />}
          />
          <Route exact path="/contact" render={() => <ContactPage />} />
        </Switch>
      </div>
    );
  }
}

export default withAuthentication(App);
