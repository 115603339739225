import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LoginButton from '../ui/button/LoginButton'
import varoLogo from '../../static/image/svg/varoLogoWhiteBG.svg';
import varoTypography from '../../static/image/varoTypography.png';
import Typo_VARO from '../../static/image/svg/varo_typo.svg';

import '../../static/css/style.css';
import DehazeIcon from '@material-ui/icons/Dehaze';
import CloseIcon from '@material-ui/icons/Close';
import InstagramIcon from '@material-ui/icons/Instagram';

const HEADER_SCROLL_TOP_OPACITY = 1.0;

const Wrapper = styled.div`
	position: fixed;
  width: 100vw;
  height: 75px;
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const ContentWrapper = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ContentContainer = styled(Grid)`
	display: flex;
	align-items: center;
	justify-content: space-between;
  background-color: rgba(255, 255, 255, ${(props) => props.opacity});
  -webkit-transition: -webkit-background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
`;

const imgFade = keyframes`
  from{
    transform:translateX(-30px);
    box-shadow: 0 0 0 1200px rgba(255, 255, 255, 0.1);
  }
  to{
    transform:translateX(0px);
    box-shadow: 0 0 0 1200px rgba(255, 255, 255, 0.8);
  }
`;

const NavSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	@media (max-width: 700px) {
		z-index: 1;
		top: 0;
    left: 0;
		position: absolute;
		padding-top: 8%;
    padding-left:5%;
		width: 60%;
		padding-bottom: 200%;
		flex-direction: column;
    background-color: ${ props => props.theme.color.VARO_PURPLE};
    box-shadow: 0 0 0 1200px rgba(255, 255, 255, 0.8);
    animation: ${imgFade} 0.5s;
	}
	/*모바일에서의 글자 위치 조정*/
	@media (max-width: 700px) {
		padding-top: 10%;
	}
`;

const BorderFlex = styled.div`
  width: 85%;
  height: 3px; 
  margin: 4%; 
  background-color: ${ props => props.theme.color.WHITE};
`;

const LogoContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
  align-self: center;
  @media (min-width: 700px) {
    margin-top: -1px;
	}
`;

const BrandName = styled.div`
  font-size: 32px;
  font-weight: 500;
	color: ${ props => props.theme.color.BLACK};
  font-family: JSDongkang-Regular;
  @media (min-width: 700px) {
    margin-top: 6px;
	}
  @media (max-width: 700px) {
    margin-top: 8px;
	}
`;

const NavText = styled.h3`
  color: ${ props => props.theme.color.BLACK};
  font-family: JSDongkang-Regular;
  margin: 10px;
  margin-top: 15px;
  font-size: 16px;
  cursor: pointer;

  @media (max-width: 700px) {
    color: ${ props => props.theme.color.WHITE};
    font-size: 22px;
    font-weight: 100;
    margin: 5%;
  }
`;

const SidebarButton = styled.h3`
	display: none;
	@media (max-width: 700px) {
		display: block;
		z-index: 100;
	}
`;

class Header_Trans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      headerBarOpacity: HEADER_SCROLL_TOP_OPACITY,
    };
    this.onClickButton = this.onClickButton.bind(this);
    this.resize = this.resize.bind(this);
  }

  onClickButton() {
    this.setState({ hidden: !this.state.hidden });
  }

  resize() {
    this.setState({ hidden: !(window.innerWidth >= 700) });
  }

  componentWillMount() { }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    window.onscroll = () => {
      const { headerBarOpacity } = this.state;
      if (headerBarOpacity <= HEADER_SCROLL_TOP_OPACITY && window.scrollY > 700) {
        this.setState({
            headerBarOpacity: 1.0,
        });
      } else if (headerBarOpacity >= 1.0 && window.scrollY <= 700) {
        this.setState({
            headerBarOpacity: HEADER_SCROLL_TOP_OPACITY,
        });
      }
    };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  render() {
    const { headerBarOpacity } = this.state;
    return (
      <Wrapper>
        <ContentWrapper>
          <div style={{flex: 2}}>

          </div>
          <div style={{flex: 2}}>
            <img src={Typo_VARO} width={100} />
          </div>
          <div style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <h3 style={{fontWeight: 400, padding: 0, margin: 0}}>
              MENU
            </h3>
            <div style={{width: 10}} />
            <DehazeIcon style={{fontSize: 30}}/>
          </div>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default Header_Trans;
