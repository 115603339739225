import firebase, { app } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";

// varo-web-prod
const firebaseConfig = {
  apiKey: "AIzaSyCMUYDZ76sJC3rrIuFmtrGJB8fWJI5-Rrg",
  authDomain: "varo-web.firebaseapp.com",
  databaseURL: "https://varo-web.firebaseio.com",
  projectId: "varo-web",
  storageBucket: "varo-web.appspot.com",
  messagingSenderId: "830635391536",
  appId: "1:830635391536:web:95ab841e9ffb3b9dfccbf5",
  measurementId: "G-11JM1PR9LN"
};

// varo-web-dev
/*
const firebaseConfig = {
  apiKey: "AIzaSyAqUo5Bp3arb0dMimWi_38D7ZaYoxKrQKM",
  authDomain: "varo-web-prod.firebaseapp.com",
  databaseURL: "https://varo-web-prod.firebaseio.com",
  projectId: "varo-web-prod",
  storageBucket: "varo-web-prod.appspot.com",
  messagingSenderId: "536692460415",
  appId: "1:536692460415:web:7813494f2763e43c97684a",
  measurementId: "G-6CKSZRJNZV"
}
*/
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.database();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

export { db, auth, functions, storage, firebase };
