import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Grid } from '@material-ui/core';
import icon_menuPlate_clip from '../../static/image/svg/icon_menuPlate_clip.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 3px 3px 10px 1px rgba(0,0,0,0.2);
  @media(max-width: 800px){
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.2);
  }
`;

const imgFade = keyframes`
  from{
    filter:brightness(70%);
  }
  to{
    filter:brightness(100%);
  }
`;

const Image = styled.img`
  padding: 1% 1%;
  width: 55rem;
  height: 40rem;
  
  @media(min-width: 1300px){
    width: 29vw;
    height: 23vw;
  }
  @media(max-width: 1300px){
    width: 27rem;
    height: 21rem;
  }
  @media(max-width: 700px){
    width: 38rem;
    height: 33rem;
  }

  ${props => props.shadow && css`
    filter: brightness(100%);
  `}
  &:hover{
    animation: ${imgFade} 3s linear infinite;
  }
`;

const NameText = styled.h1`
  color: ${props => props.theme.color.BLACK};
  margin: 0;
  @media(min-width: 1300px){
    font-size: 1.5rem;
  }
  @media(max-width: 1300px){
    font-size: 1.2rem;
  }
  @media(max-width: 800px){
    font-size: 7px;
  }
`;

const ColorDeck = styled.div`
  width: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  @media(max-width: 800px){
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`

export default class MENU_PLATE extends Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  render() {
    const { menu_title, menu_image, plate_width, menu_colorCode } = this.props;

    return (
      <Wrapper style={{width: plate_width}}>
        <img src={icon_menuPlate_clip} style={{width: plate_width * 0.3, position: "relative", marginTop: -plate_width * 0.18}} />
        <div style={{height: plate_width * 0.15}} />
        <img src={menu_image} style={{width: '60%'}} />
        <div style={{height: plate_width * 0.1}} />
        <NameText>{menu_title}</NameText>
        <div style={{height: plate_width * 0.12}} />
        <ColorDeck style={{height: plate_width * 0.15, backgroundColor: menu_colorCode}} />
      </Wrapper >
    )
  }
}