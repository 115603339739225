import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';
import varoTypography from '../../static/image/svg/typo_varo.svg';
import '../../static/css/style.css';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SignUpModal from '../../page/login/signUp';
import SignInModal from '../../page/login/signIn';
import FindPasswordModalBase from '../../page/login/find_password';
import NoticeModal from './noticeModal';

const HEADER_SCROLL_TOP_OPACITY = 1.0;

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 100;
  font-family: IBMPlexSansKR-Regular;
`;

const ContentWrapper = styled.div`
	width: calc(100%);
`;

const ContentContainer = styled(Grid)`
	display: flex;
	align-items: center;
	justify-content: center;
  background-color: #FFFFFF;
  @media(min-width: 1300px){
    padding: 0 10%;
    height: 80px;
  }
  @media(max-width: 1300px){
    padding: 0 10%;
    height: 55px;
  }
`;

const LOGO = styled.img`
  margin-bottom: 0;
  @media(min-width: 1300px){
    padding: 0 4%;
    height: 55px;
  }
  @media(max-width: 1300px){
    padding: 0 1%;
    height: 40px;
  }
`

const LogoContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  @media (min-width: 700px) {
    margin-top: -1px;
	}
`;

class SimpleHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      onTopScroll: true,
      headerBarOpacity: HEADER_SCROLL_TOP_OPACITY,
      userIconBgColor: '#FFFFFF',
      isSignInModalOpen: false,
      isSignUpModalOpen: false,
      isFindPasswordModalOpen: false,
      isNoticeModalOpen: false,
    };
    this.resize = this.resize.bind(this);
  }

  resize() {
    this.setState({ hidden: !(window.innerWidth >= 700) });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
    window.onscroll = null;
  }

  render() {
    const { headerBarOpacity, onTopScroll } = this.state;
    return (
      <Wrapper>
        <ContentWrapper>
          <Grid container>
            <ContentContainer item xs={12} sm={12} md={12} lg={12} opacity={headerBarOpacity}>
              <NavLink exact activeClassName="active" to="/" style={{ textDecoration: 'none' }}>
                <LogoContainer>
                  <LOGO src={varoTypography} /> 
                </LogoContainer>
              </NavLink>
            </ContentContainer>
          </Grid>
        </ContentWrapper>
      </Wrapper>
    );
  }

  _clickUserIcon = () => {
    if (this._isUserLoggedIn()) {
      this.props.history.push(ROUTES.MY_PAGE);
    } else {
      this.setState({ isSignInModalOpen: true });
    }
  }

  _isUserLoggedIn = () => {
    return this.props.sessionStore.authUser
  }

  closeSignInModal = (respond) => {
    if (respond === "close") {
      this.setState({ isSignInModalOpen: false })
    } else if (respond === "findPassword") {
      this.setState({ 
        isSignInModalOpen: false,
        isFindPasswordModalOpen: true
       });
    }
  }

  closeSignUpModal = () => {
    this.setState({ isSignUpModalOpen: false });
  }

  closeFindPasswordModal = (respond) => {
    if (respond === "close") {
      this.setState({ isFindPasswordModalOpen: false });
    } else if (respond === "sendPasswordResetEmail") {
      this.setState({
        isFindPasswordModalOpen: false,
        isNoticeModalOpen: true
      })
    }
  }

  closeNoticeModal = () => {
    this.setState({ isNoticeModalOpen: false });
  }

  moveToSignUp = () => {
    this.setState({ 
      isSignInModalOpen: false,
      isSignUpModalOpen: true
    });
  }
}

export default compose(
  inject('sessionStore'),
  observer,
  withRouter
)(SimpleHeader);
//export default Header;
