import React from 'react';
import ReactDOM from 'react-dom';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import Firebase, { FirebaseContext } from './components/firebase';
import { Provider } from 'mobx-react';
import store from './stores';
// Redux
import { ParallaxProvider } from 'react-scroll-parallax';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './redux/reducers';
import sagas from './redux/sagas';
import App from './App';
import styled, { ThemeProvider } from 'styled-components';
// Theme
import StyledComponentsTheme from './theme/StyledComponentsTheme'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import MuiTheme from './theme/MuiTheme';
import reportWebVitals from './components/utils/reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';
// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
reportWebVitals();
window.IMP.init("imp87339235");
// Mount it on the Store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* Create store
const store = createStore(
  reducers, // preloadedState,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
  )
);
*/
// then run the sagas
// sagaMiddleware.run(sagas);

// define meta
const meta = {
  title: 'START VARO',
  description: '신선하고 맛있는 재료로 만든 최고의 비건 밀키트! 지금 바로 먹어보세요',
  canonical: 'https://spirit-varo.com/',
  image: './static/image/img_home_main.png',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'varo, vegan, weekly plan',
    }
  }
}

ReactDOM.render(
  <DocumentMeta {...meta}>
    <MuiThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={StyledComponentsTheme}>
        <ParallaxProvider>
          <FirebaseContext.Provider value={new Firebase()}>
            <Provider {...store}>
              <Router>
                <App />
              </Router>
            </Provider>
          </FirebaseContext.Provider>
        </ParallaxProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </DocumentMeta>,
  document.getElementById('root')
);