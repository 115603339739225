import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withFirebase } from '../../components/firebase';
import styled, { keyframes } from 'styled-components';

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 10;
`

const LoginModal = styled.div`
  z-index: 10;
  width: 480px;
  height: 360px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  margin: 150px auto;
  padding: 20px;
  background: #FFFFFF;
  overflow: auto;
  border-radius: 5px;
`

const SpanClose = styled.div`
  float: right;
  font-size: 25px;
`

const ModalContents = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const LoginInput = styled.input`
  margin-top: 15px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const LoginBtn = styled.button`
  height: 40px;
  font-size: 14px;
  padding: 13px 30px;
  cursor: pointer;
  background-color: #649330;
  color: #FFFFFF;
  line-height: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-radius: 3px;
  border-style: none;
`

const INITIAL_STATE = {
  email: '',
  error: null,
};

@observer
class FindPasswordModalBase extends Component {
  @observable loading

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      isSignInModalOpen: true
    }
    this.loading = false;
  }
 
  render() {
    const { email, error } = this.state;
    const { isOpen } = this.props;
    const isInvalid = email === '';

    return(
      isOpen ? 
      <Modal>
        <LoginModal>
          <SpanClose onClick={() => this.props.close("close")} style={{cursor: 'pointer'}}>
            &times;
          </SpanClose>
          <ModalContents>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <h3 style={{margin: 5, fontSize: 24, marginBottom: 20}}>
                비밀번호 찾기
              </h3>
            </div>
            <h3 style={{margin: 5, fontSize: 12, marginBottom: 20}}>
              가입한 이메일을 입력하고 비밀번호 찾기 버튼을 눌러주세요. 비밀번호를 재설정할 수 있는 메일이 발송됩니다.
            </h3>
            <LoginInput
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <LoginBtn onClick={this.onSubmit} disabled={isInvalid} style={{cursor: 'pointer'}}>
              비밀번호 찾기
            </LoginBtn>
            {error && <p style={{color: 'red'}}>{error}</p>}
          </ModalContents>
        </LoginModal>
      </Modal> : <div />
    )
  }

  onSubmit = (event) => {
    const { email } = this.state;
    this.loading = true
    this.props.firebase
    .doSendPasswordResetEmail(email)
    .then(() => {
      this.setState({
        ...INITIAL_STATE
      })
      this.props.close("sendPasswordResetEmail");
      this.loading = false;
    })
    .catch(error => {
      this.loading = false;
      if (error.code === 'auth/invalid-email') {
        this.setState({ error: "유효하지 않은 이메일입니다." })
      } else if (error.code === 'auth/user-not-found') {
        this.setState({ error: "일치하는 유저가 없습니다." })
      } else {
        this.setState({ error: error.message });
      }
    });
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
}

const FindPasswordModal = compose(
  withRouter,
  withFirebase,
)(FindPasswordModalBase);

export default FindPasswordModal;