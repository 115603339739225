import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withFirebase } from '../../../components/firebase';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';

const Wrapper = styled.div`
  margin-bottom: 150px;
  margin-left: 10vw;
  padding-top: 12vh;
  width: 800px;
  min-width: 800px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LoginInput = styled.input`
  width: 500px;
  height: 50px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #2B2B2B;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 18px;
`

const GreenCheckbox = withStyles({
  root: {
    color: '#BBB',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

@observer
class AccountBoxBase extends Component {
  @observable loading

  constructor(props) {
    super(props);
    this.data = [, ,];
    this.state = {
      width: 0,
      height: 0,
      phoneChecked: this.props.sessionStore.authUser.phone_agreement || false,
      emailChecked: this.props.sessionStore.authUser.email_agreement || false,
      phone: this.props.sessionStore.authUser.phone,
    };
    this.loading = false;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
  }

  handleChangeCheckBox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    
    this.props.firebase.db.ref('user_order/week3').once('value')
    .then((snap) => {
      if (snap && snap.val()) {
        this.setState({datas : snap.val()})
      }
    })
    .then(() => {
      let amount = 0
      let menu1 = 0
      let menu2 = 0
      let menu3 = 0
      let menu4 = 0
      let count = 0
      Object.keys(this.state.datas).map((key, index) => {
        amount = amount + this.state.datas[key].amount
        menu1 = menu1 + this.state.datas[key].menu1_order
        menu2 = menu2 + this.state.datas[key].menu2_order
        menu3 = menu3 + this.state.datas[key].menu3_order
        menu4 = menu4 + this.state.datas[key].menu4_order
        count = count + 1
      })
      console.log(amount, menu1, menu2, menu3, menu4, count)
    })
    .catch(console.log)
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  isBigScreen() {
    return this.state.height > 1000? true : false
  }

  render() {
    const { phoneChecked, emailChecked, phone } = this.state;

    return (
      <Wrapper>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
          <h1 style={{fontWeight: '900'}}>
            USER INFO
          </h1>
          <div style={{height: 50}} />
          <p style={{fontSize: 14, color: '#2B2B2B'}}>
            이름
          </p>
          <div style={{width: 500, height: 50, marginBottom: 20, padding: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#2B2B2B', borderRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <p style={{fontSize: 18, margin: 0}}>
              {this.props.sessionStore.authUser.username}
            </p>
          </div>
          <p style={{fontSize: 14, color: '#2B2B2B'}}>
            이메일
          </p>
          <div style={{width: 500, height: 50, marginBottom: 20, padding: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#2B2B2B', borderRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <p style={{fontSize: 18, margin: 0}}>
              {this.props.sessionStore.authUser.email}
            </p>
          </div>
          <p style={{fontSize: 14, color: '#2B2B2B'}}>
            전화번호
          </p>
          <LoginInput
            name="phone"
            value={phone}
            onChange={this.onChange}
            type="text"
            placeholder={"입력해주세요"}
          />
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <GreenCheckbox
              checked={phoneChecked}
              name="phoneChecked"
              onChange={this.handleChangeCheckBox}
            />
            <p style={{fontSize: 16, margin: 0}}>
              휴대폰을 통한 배송 정보 및 정기배송 레시피 정보 수신에 동의합니다. (선택)
            </p>
          </div>
          <div style={{height: 10}} />
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <GreenCheckbox
              checked={emailChecked}
              name="emailChecked"
              onChange={this.handleChangeCheckBox}
            />
            <p style={{fontSize: 16, margin: 0}}>
            이메일을 통한 정기배송 레시피 정보 수신에 동의합니다. (선택)
            </p>
          </div>
          <div style={{height: 30}} />
          <button onClick={this._onClickConfirm}
            style={{color: '#FFF', backgroundColor: '#2BCC65', boxShadow: '3px 3px 10px 1px rgba(0,0,0,0.2)', borderWidth: 0, width: 500, height: 50, fontSize: 17, fontWeight: '600', }}>
            수정하기
          </button>
        </div>
      </Wrapper>
    )
  }

  _onClickConfirm = (event) => {
    const { phone, phoneChecked, emailChecked } = this.state;

    this.loading = true;
    this.props.firebase.db.ref(`users/${this.props.sessionStore.authUser.uid}`)
      .update({ 
        phone: phone,
        phone_agreement: phoneChecked,
        email_agreement: emailChecked,
      })
      .then(() => {
        this.loading = false;
        alert("저장되었습니다.")
        this.refreshPage();
      })
      .catch(console.log)
    this.loading = false;
    event.preventDefault();
  }

  refreshPage = () => {
    window.location.reload();
  }
}


const AccountBox = compose(
  withRouter,
  withFirebase,
  inject('sessionStore'),
  observer,
)(AccountBoxBase);

export default AccountBox;