import React from 'react';
import styled from 'styled-components';
import ScrollToTop from '../../components/base/ScrollToTop';

import Header from '../../components/base/Header';
import Header_Trans from '../../components/base/Header_Trans';
import Footer from '../../components/base/Footer';
import MainBox from './MB';
// import FeatureBox from './featureBox';
// import BackgroundBox from './BackgroundBox';
// import CharacterView from './CharacterView';
// import CommentBox from './CommentBox';
// import MenuView from './MenuView';
// import ProcessView from './ProcessView';

import Header_Mobile from '../../components/base/mobile/header_mobile';

import Img_home_samii from '../../static/image/Img_home_samii.jpg';
import Img_home_samii_mobile from '../../static/image/Img_home_samii_mobile.jpg';


import NoticeModal from '../../components/base/noticeModal';
import sep_home from '../../static/image/svg/sep_home.svg';

const Wrapper = styled.div`
  width: 100%;
  height: auto !important;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
`;

const MainTitleText = styled.h1`
  font-size: 50px;
  font-weight: bold;
  font-family: NotoSansCJKkr-Regular;
  margin: 0;
`

class HomeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      isOpen: true,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  checkPopup = () => {
    const currentTime = Math.floor(new Date().getTime());
    const expireTime = Number(window.localStorage.getItem('expireTime')) || null;

    console.log(`currentTime : ${currentTime}, expireTime : ${expireTime}`)

    if (expireTime >= currentTime) {
      this.close();
    } else if (expireTime < currentTime) {
      window.localStorage.removeItem('expireTime')
    }
  }

  render() {
    const { width, height } = this.state;
    const isMobile = width < 800;
    if (isMobile) {
      return (
        <Wrapper>
          <ScrollToTop />
          <Header_Mobile screen="HOME"/>
          <MainBox />
          {/* <FeatureBox />
          <MenuView />
          
          <ProcessView />
    
          <CommentBox /> */}
          <Footer />
          {/* <NoticeModal 
            close={this.close}
            isOpen={this.state.isOpen}
            title={"바로(VARO) 혹서기 휴식 공지"}
            notice1={`안녕하세요. \n\n연이은 폭염으로 인해 배송에 문제가 생길 수 있어 주간 서비스를 잠정적으로 중단하기로 했습니다. 단체 주문은 수량에 따라 직접 배송이 가능합니다. 홈페이지 하단에 있는 이메일로 문의하시면 안내드리겠습니다.`} 
            notice2={`인스타그램(@varo_mealkit)과 홈페이지를 통해 꾸준히 소식을 전하고 소통할 테니 조금만 기다려주세요! 더운 여름날, 몸조리 잘하시고 건강한 모습으로 다시 뵙길 바랍니다. \n\n감사합니다. \n\nVARO 드림\n모두를 위한 식탁, 바로`}
          /> */}
        </Wrapper>
      )
    } else {
      return (
        <div>
      <Wrapper>
        <ScrollToTop />
        <Header />
        
        {/* <FeatureBox />
        <img src={sep_home} style={{width: '100vw', marginTop: '-2vw'}} />
        <CharacterView style={{zIndex: 2, backgroundColor: 'white'}}/>
        
        <ProcessView />
  
        <CommentBox /> */}
        <MainBox />
        <Footer />
        {/*
        <NoticeModal notice1="바로가 곧 여러분들 곁으로 돌아옵니다!" notice2="더 발전한 모습의 바로로 바로 찾아뵙겠습니다!" />
        */}
      </Wrapper>
      </div>
      );
    }
  }
}

export default HomeLayout;
