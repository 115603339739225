import React from 'react';
import styled from 'styled-components';

import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withFirebase } from '../../components/firebase';
import {firebase} from '../../firebase';

import Header from '../../components/base/Header';
import SimpleHeader from '../../components/base/Simple_Header';
import Footer from '../../components/base/Footer';
import ScrollToTop from '../../components/base/ScrollToTop';
import Header_Mobile from '../../components/base/mobile/header_mobile';

import * as ROUTES from '../../../src/constants/routes';

const Wrapper = styled.div`
  width: 100%;
  height: auto !important;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: NotoSansCJKkr-Regular;
`;

const LOGO = styled.img`
  width: 20vw;
`

const ContentWrapper = styled.div`
  width: 70%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const LoginBtn = styled.button`
  height: 45px;
  font-size: 14px;
  font-weight: bold;
  padding: 13px 30px;
  cursor: pointer;
  background-color: #5BBA62;
  color: #FFFFFF;
  border-radius: 3px;
  border-style: none;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`

class BillPageBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      isOpen: true,
    };
  }

  componentDidMount() {
    window.IMP.init("imp87339235");
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <Wrapper>
        <ScrollToTop />
        <SimpleHeader style={{position: 'absolute'}} />
        <ContentWrapper>
          <div style={{height: '10vh'}}/>
          <div style={{maxWidth: 400, width: '90%', padding: '20px 20px', borderStyle: 'solid', borderWidth: 1, borderColor: '#383838'}}>
            <p style={{fontSize: 20, fontWeight: 'bold', marginTop: 0}}>개별 결제 페이지</p>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', marginTop: 30}}>
              <p style={{fontSize: 16, fontWeight: 'bold'}}>결제내용</p>
              <p style={{fontSize: 12, margin: 0}}>11월 4주 단체주문 : 배송 10건</p>
              <p style={{fontSize: 12}}>35,000원</p>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: 400, width: '90%', marginTop: 10}}>
              <p style={{fontSize: 16, fontWeight: 'bold'}}>총 결제 금액</p>
              <p style={{fontSize: 12, margin: 0}}>35,000원</p>
            </div>
            <div style={{height: 10}} />
          </div>
          <LoginBtn onClick={() => this.handleSubmit(35000)} style={{maxWidth: 400, width: '90%', alignSelf: 'center'}}>
            결제하기
          </LoginBtn>
        </ContentWrapper>
        <Footer />
      </Wrapper>
    );
  }


  handleSubmit = (price) => {
    const uid = "youthunion"
    let payMethod = 'card'
    let name = 'youthunion'
    let phone = 'none'
    let address = "none"
    let zoneCode = "none"

    if (!price) {
      return window.alert("---PRICE ZERO ERROR---");
    }
    if (payMethod === 'account') {
      this.setState({ showAccountPayModal: true })
    } else if (payMethod === 'card') {
      window.IMP.request_pay(
        {
          // param
          pg: "html5_inicis",
          pay_method: "card",
          merchant_uid: `merchant_${new Date().getTime()}`,
          name: "VARO",
          amount: price,
          buyer_name: name,
          buyer_tel: phone,
          buyer_addr: address,
          buyer_postcode: zoneCode,
        },
        (rsp) => {
          // callback
          if (rsp.success) {
            const key = this.props.firebase.db.ref(`user_order/${uid}`).push().key;
            Promise.resolve()
            .then(() => {
              this.props.firebase.db.ref(`user_order/${uid}`).child(key)
              .update({ updatedAt: firebase.database.ServerValue.TIMESTAMP,
                        uid: uid,
                        amount: price,
                        name: name,
                        phone: phone,
                        zoneCode: zoneCode,
                        address1: address,
                        oid: key,
                      })
              .catch(console.log)
            })
            this.props.history.push(ROUTES.ORDER_COMPLETE)
          } else {
            // 결제 실패 시 로직
            console.log(rsp);
            window.alert(rsp.error_msg);
          }
        }
      );
    }
  };

}

const Bill_S_Page = compose(
  withRouter,
  withFirebase,
  inject('sessionStore'),
  inject('orderStore'),
  observer,
)(BillPageBase);

export default Bill_S_Page;