import React, { Component } from 'react';
import styled from 'styled-components';
import varoTypography from '../../static/image/svg/typo_varo.svg';
import InstagramIcon from '@material-ui/icons/Instagram';
import varo_typo from '../../static/image/svg/varo_typo.svg'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  z-index: 1;
`;

const ContentWrapper = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
`;

const MenuName = styled.h3`
  font-size: 10px;
  color: ${props => props.theme.color.BLACK};
  font-family: Muli, IBMPlexSansKR-Regular;
  font-weight: 300;
  margin: 4px;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { width } = this.state;

    if (width < 800) {
      return (
        <Wrapper>
          <ContentWrapper style={{flexDirection: 'column', alignItems: 'center'}}>
            <img src={varo_typo} style={{width: 40}} />
            <div style={{height: 15}} />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <MenuName>
                  바로(VARO)
                </MenuName>
                <div style={{width: 20}} />
                <MenuName>
                  대표 : 이원정
                </MenuName>
                <div style={{width: 10}} />
                <MenuName>
                  사업자등록 : 325-16-01442, 통신판매업신고 : 덕양구0493호
                </MenuName>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <MenuName>
                  연락처 : 0507-0094-2157
                </MenuName>
                <div style={{width: 20}} />
                <MenuName>
                  이메일 : varospirit@gamil.com
                </MenuName>
                <div style={{width: 20}} />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <MenuName>
                  주소지 : 경기도 고양시 덕양구 삼원로 51, 2층 218호
                </MenuName>
                <div style={{width: 20}} />
                <InstagramIcon style={{width: 20}} />
                <MenuName>
                  varo_mealkit
                </MenuName>
              </div>
            </div>
          </ContentWrapper>
          <div style={{height: 10}} />
        </Wrapper>
      )
    } else {
      return (
        <Wrapper>
          <ContentWrapper>
            <img src={varo_typo} style={{width: 60}} />
            <div style={{width: 50}} />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <MenuName>
                  바로(VARO)
                </MenuName>
                <div style={{width: 20}} />
                <MenuName>
                  대표 : 이원정
                </MenuName>
                <div style={{width: 10}} />
                <MenuName>
                  사업자등록 : 325-16-01442, 통신판매업신고 : 덕양구0493호
                </MenuName>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <MenuName>
                  연락처 : 0507-0094-2157
                </MenuName>
                <div style={{width: 20}} />
                <MenuName>
                  이메일 : varospirit@gmail.com
                </MenuName>
                <div style={{width: 20}} />
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <MenuName>
                  주소지 : 경기도 고양시 덕양구 삼원로 51, 2층 218호
                </MenuName>
                <div style={{width: 20}} />
                <InstagramIcon style={{width: 20}} />
                <MenuName>
                  varo_mealkit
                </MenuName>
              </div>
            </div>
          </ContentWrapper>
          <div style={{height: 10}} />
        </Wrapper>
      )
    }
  }
}

export default Header;