import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { withFirebase } from '../firebase';
import styled from 'styled-components';

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 99;
`

const LoginModal = styled.div`
  z-index: 10;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  margin: 150px auto;
  padding: 20px;
  background: #FFFFFF;
  overflow: auto;
  border-radius: 10px;
`

const SpanClose = styled.div`
  float: right;
  font-size: 25px;
`

const ModalContents = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 10px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Btn = styled.button`
  height: 40px;
  font-size: 14px;
  padding: 13px 30px;
  cursor: pointer;
  background-color: #000;
  color: #FFFFFF;
  line-height: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-radius: 3px;
  border-style: none;
`

@observer
class NoticeModalBase extends Component {
  @observable loading

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      isOpen: true
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  close = () => {
    this.setState({ isOpen: false })
  }
 
  render() {
    const { isOpen } = this.props;
    return(
      isOpen ? 
      <Modal>
        <LoginModal style={{width: this.state.width < 800? 300: 400}}>
          <SpanClose onClick={this.props.close} style={{cursor: 'pointer'}}>
            &times;
          </SpanClose>
          <ModalContents>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <h3 style={{margin: 5, fontSize: this.state.width < 800? 16: 24, marginBottom: 20}}>
                {this.props.title}
              </h3>
            </div>
            <h6 style={{marginTop: 10, fontSize: this.state.width < 800? 11: 14, whiteSpace: 'pre-line'}}>{this.props.notice}</h6>
            <Btn onClick={this.props.close}>
              확인했습니다
            </Btn>
          </ModalContents>
        </LoginModal>
      </Modal> : <div />
    )
  }
}

const NoticeModal = compose(
  withRouter,
  withFirebase,
)(NoticeModalBase);

export default NoticeModal;