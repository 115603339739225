import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withFirebase } from '../../../components/firebase';
import _ from 'lodash';
import AddressModal from '../addressModal';
import { convertWeektoString, getRecipeNames } from "../../../components/utils/orderHelper";
import * as ROUTES from '../../../constants/routes';
import { MDBBtn } from 'mdbreact';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MENU_INFO_COMBINED from '../../order/menu_info_constants/menu_info_combined';

const Wrapper = styled.div`
  margin-bottom: 150px;
  margin-left: 10vw;
  padding-top: 12vh;
  width: 800px;
  min-width: 800px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LabelContentDescription = styled.h1`
  font-size: 20px;
  font-family: IBMPlexSansKR-Regular;
  font-weight: 500;
  color: #000;
`

//background-color: #F7D373;
const LoginBtn = styled.button`
  width: 600px;
  height: 50px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  background-color: #000;
  color: #FFF;
  line-height: 1px;
  margin-top: 30px;
  border-radius: 3px;
  border-style: none;
  align-self: center;
`

const ModalContents = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const SpanClose = styled.div`
  float: right;
  font-size: 25px;
`

const OrderModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: auto;
`

const AddressModal1 = styled.div`
  z-index: 10;
  width: 500px;
  height: 750px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  margin: 150px auto;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
`

const TextBox = styled.div`
  margin-top: 15px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const Label = styled.div`
  font-size: 14px;
  color: #BBBBBB;
  margin-top: 10px;
  margin-bottom: -15px;
  margin-left: 4px;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
`;

const GreenCheckbox = withStyles({
  root: {
    color: '#BBB',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class OrderInfoBoxBase extends Component {
  constructor(props) {
    super(props);
    this.data = [, ,];
    this.state = {
      width: 0,
      height: 0,
      phoneChecked: false,
      emailChecked: false,
      week1_data: null,
      week2_data: null,
      order_data: null,
      addressPick: "",
      addressDatas: [],
      isOrderConfirmModalOpen: false,
      orderKey: null,
      isOrderConfirmModalOpen2: false,
      isOrderCancelModalOpen: false,
      isAddressModalOpen: false,
      A: null,
      m1: 0,
      m2: 0,
      m3: 0,
      amount: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    this.recipeNames = getRecipeNames();
    this.toShowOrderInfoList = ['11월 3주차 화요일', '11월 3주차 목요일', '11월 4주차 화요일', '11월 4주차 목요일', '11월 5주차 화요일', '12월 1주차 목요일']
  }

  handleChangeCheckBox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  _clickAddressKey = (item) => {
    this.setState({ addressPick: item })
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (this.props.sessionStore.authUser.address) {
      this.setState({
        addressDatas: Object.keys(this.props.sessionStore.authUser.address)
      })
    }

    this.props.firebase.db.ref(`/user_order/${this.props.sessionStore.authUser.uid}`).once('value')
    .then(snap => {
      if(snap && snap.val()) {
        const order_data = snap.val();
        let datas = {};
        Object.keys(order_data).map(key => {
          if (this.toShowOrderInfoList.includes(order_data[key].week)) {
            console.log(1, order_data[key].week)
            datas[key] = order_data[key]
          }
        })
        this.setState({ order_data: datas })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  isBigScreen() {
    return this.state.height > 1000? true : false
  }

  orderStatusToString = (status) => {
    if (status === 1) {
      return "주문 완료(결제 확인 전)"
    } else if (status === 2) {
      return "결제 완료"
    } else {
      return null;
    }
  }

  render() {
    const { addressPick, isOrderConfirmModalOpen, isOrderConfirmModalOpen2, isOrderCancelModalOpen,
       isAddressModalOpen, week2_data, week1_data, order_data } = this.state;

    return (
      <Wrapper>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
          <h1 style={{fontWeight: '900'}}>
            ORDER INFO
          </h1>
          <div style={{height: 50}} />
          <p style={{fontSize: 14, color: '#2B2B2B'}}>
            {`주문내역`} 
          </p>
          {
            _.size(order_data) > 0?
            Object.keys(order_data).map(key => (
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                <div style={{width: 500, height: 50, marginBottom: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#2B2B2B', borderRadius: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                  <p style={{fontSize: 18, margin: 0}}>
                    {`(${order_data[key].week} 발송) ${order_data[key].amount}원`}
                  </p>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <p onClick={() => {this._openOrderConfirmModal(key)}} style={{fontSize: 12, margin: 0, cursor: 'pointer'}}>
                      확인하기
                    </p>
                    <p style={{fontSize: 12, margin: 3}}>
                      /
                    </p>
                    <p onClick={this._openOrderCancelModal} style={{fontSize: 12, margin: 0, cursor: 'pointer'}}>
                      취소하기
                    </p>
                  </div>
                </div>
              </div>
            )) : 
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
              <div style={{width: 500, height: 50, marginBottom: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#2B2B2B', borderRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                <p style={{fontSize: 18, margin: 0}}>
                  {`주문내역이 없습니다`}
                </p>
              </div>
            </div>
          }
          
          <div style={{height: 10}} />
          <p style={{fontSize: 14, color: '#2B2B2B'}}>
            배송지 정보
          </p>
          {this._renderAddressContent(addressPick)}
          { this.props.sessionStore.authUser.address? 
            Object.keys(this.props.sessionStore.authUser.address).map(item => (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: "center", marginRight: 30}}>
              {this.state.addressPick === item ?
                <CheckBoxIcon style={{width: 24, height: 24, marginRight: 7, cursor: 'pointer'}} /> :
                <CheckBoxOutlineBlankIcon 
                  onClick={() => {this._clickAddressKey(item)}}
                  style={{width: 24, height: 24, marginRight: 7, cursor: 'pointer'}} />
              }
              <LabelContentDescription style={{marginTop: 4}}>
                {item}
              </LabelContentDescription>
              <button onClick={() => {this._deleteAddress(item)}} color="danger"
                style={{color: '#FFF', backgroundColor: 'red', width: 40, height: 20, borderRadius: 10, fontSize: 10, marginLeft: 10, fontWeight: '600', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                제거
              </button>
            </div>
            )) : 
            <LabelContentDescription>
              저장된 배송지 정보가 없습니다
            </LabelContentDescription>
          }
          <div style={{height: 30}} />
          {isOrderConfirmModalOpen ? this._renderOrderConfirmModal(this.state.orderKey) : null}
          {isOrderCancelModalOpen ? this._renderOrderCancelModal() : null}
          <AddressModal isOpen={isAddressModalOpen} close={this._closeAddressModal} style={{overflow: 'auto'}} />
        </div>
      </Wrapper>
    )
  }

  _renderAddressContent = (user) => {
    return(
      <div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
          <div style={{width: 250, height: 50, marginBottom: 20, padding: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#2B2B2B', borderRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <p style={{fontSize: 18, margin: 0}}>
              {user? this.props.sessionStore.authUser.address[user].zoneCode : null}
            </p>
          </div>
          <div style={{width: 30}} />
          <button onClick={this._openAddressModal}
            style={{color: '#FFF', borderRadius: 5, backgroundColor: '#000', width: 150, height: 40, fontSize: 15, marginBottom: 22, fontWeight: '600', borderWidth: 0 }}>
            + 주소 추가하기
          </button>
        </div>
        <div style={{overflow: 'auto', width: 500, height: 50, marginBottom: 20, padding: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#2B2B2B', borderRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
          <p style={{fontSize: 18, margin: 0}}>
            {user? this.props.sessionStore.authUser.address[user].address1 : null}
          </p>
        </div>
        <div style={{width: 500, height: 50, marginBottom: 20, padding: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#2B2B2B', borderRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
          <p style={{fontSize: 18, margin: 0}}>
            {user? this.props.sessionStore.authUser.address[user].address2 : null}
          </p>
        </div>
      </div>
    )
  }

  _openAddressModal = () => {
    if (this.state.addressDatas.length >= 3) {
      alert("3개를 초과하여 주소를 등록할 수 없습니다.")
    } else {
      this.setState({ isAddressModalOpen: true })
    }
  }

  _closeAddressModal = () => {
    if (this.props.sessionStore.authUser.address) {
      this.setState({ 
        isAddressModalOpen: false,
        addressDatas: Object.keys(this.props.sessionStore.authUser.address),
        addressPick: "",
      })
    } else {
      this.setState({ 
        isAddressModalOpen: false
      })
    }
    
    this.props.history.push(ROUTES.MY_PAGE);
    this.refreshPage()
  }

  _deleteAddress = (user) => {
    this.props.firebase.db.ref(`users/${this.props.sessionStore.authUser.uid}/address/${user}`)
    .remove()
    .catch(console.log)
    .then(() => {
      if (this.props.sessionStore.authUser.address) {
        this.setState({ addressDatas: Object.keys(this.props.sessionStore.authUser.address) })
      } else {
        this.setState({ addressDatas: [] })
      }
    })
    .then(() => {
      this.refreshPage()
      this.props.history.push(ROUTES.MY_PAGE);
    })
  }

  _renderOrderConfirmModal = (key) => {
    let orderData = this.state.order_data[key]
    let weekNumber = orderData.week
    return(
      <OrderModal>
        <AddressModal1 style={{height: 730}}>
          <SpanClose onClick={() => this._closeOrderConfirmModal()} style={{cursor: 'pointer'}}>
            &times;
          </SpanClose>
          <ModalContents>
            <h3 style={{margin: 5, fontSize: 28, marginBotom: 20, fontFamily: 'TradeGothicLTStd', fontWeight: '600'}}>
              VARO ORDER
            </h3>
            <div style={{height: 10}} />
            
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{width: '40%'}}>
                <Label>받으시는 분 이름</Label>
                <TextBox style={{width: '100%'}}>
                  {orderData.name}
                </TextBox>
              </div>
              <div style={{width: '5%'}} />
              <div style={{width: '55%'}}>
                <Label>연락처</Label>
                <TextBox style={{width: '100%'}}>
                  {orderData.phone}
                </TextBox>
              </div>
            </div>
            <Label>주소</Label>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <TextBox style={{width: '65%'}}>
                {orderData.zoneCode}
              </TextBox>
            </div>
            <TextBox>{orderData.address1}</TextBox>
            <TextBox>{orderData.address2}</TextBox>
            <Label>배송정보</Label>
            <TextBox style={{width: '100%'}}>
              {orderData.delivery_code? `(CJ대한통운) ${orderData.delivery_code}` : ''}
            </TextBox>
            <div style={{height: 20}} />
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5}}>
              <div style={{width: '50%'}}>
                <h2>결제 금액 : </h2>
              </div>
              <div style={{width: '50%', paddingRight: '10%'}}>
                <h2>{orderData.amount}원</h2>
              </div>
            </div>
            { this.recipeNames.map(menu => {
              if (Object.keys(orderData).includes(menu)) {
                return (
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <p style={{fontSize: 13, fontWeight: 500, marginRight: 10}}>
                      {menu}
                    </p>
                    <p style={{fontSize: 13, fontWeight: 500}}>
                      {orderData[menu]}인분,
                    </p>
                  </div>
                )
              }
            })}
            <LoginBtn onClick={() => {this._closeOrderConfirmModal()}} style={{width: 440}}>
              확인
            </LoginBtn>
          </ModalContents>
        </AddressModal1>
      </OrderModal>
    )
  }

  _renderOrderCancelModal = () => {
    return(
      <OrderModal>
        <AddressModal1 style={{height: 250}}>
          <SpanClose onClick={() => this._closeOrderCancelModal()} style={{cursor: 'pointer'}}>
            &times;
          </SpanClose>
          <ModalContents>
            <h2 style={{margin: 5, fontSize: 20, marginBotom: 20, fontFamily: 'TradeGothicLTStd', fontWeight: '600'}}>
              주문 취소 안내
            </h2>
            <div style={{height: 10}} />
            <h2 style={{margin: 5, fontSize: 16, marginBotom: 20, fontFamily: 'TradeGothicLTStd', fontWeight: '500'}}>
              주문 상세 내역을 홈페이지 아래에 있는 이메일이나 인스타그램 DM으로 보내주시면 바로 진행 도와드립니다.
            </h2>
            <LoginBtn onClick={() => this.setState({isOrderCancelModalOpen: false })} style={{width: 300, marginTop: 30, fontSize: 20, height: 40}}>
              확인
            </LoginBtn> 
          </ModalContents>
        </AddressModal1>
      </OrderModal>
    )
  }

  _clickOrderCancelBtn = () => {
    const price = this.state.week2_data.amount

    this.props.firebase.db.ref(`/user_order/week4/${this.props.sessionStore.authUser.uid}`)
    .remove()
    .catch(console.log)
    
    let order_number = 0
    let sum = 0
    let isExist = false

    this.props.firebase.db.ref(`/order_info/week4/cancel`).once('value')
    .then((snap) => {
      if (snap.val() && snap) {
        order_number = snap.val().order_number
        sum = snap.val().sum
        isExist = true
        alert(snap.val().order_number)
      } 
    })
    .then(() => {
      if (isExist) {
        this.props.firebase.db.ref(`/order_info/week4/cancel`).update({
          order_number: order_number + 1,
          sum: sum + price
        })
        .catch(console.log)
      } else {
        this.props.firebase.db.ref(`/order_info/week4/cancel`)
        .set({
          order_number: 1,
          sum: price
        })
        .catch(console.log)
      }
    })
    .then(() => {
      this.setState({ isOrderCancelModalOpen: false })
      this.refreshPage()
    })
  }

  _openOrderCancelModal = () => {
    this.setState({ isOrderCancelModalOpen: true })
  }

  _closeOrderCancelModal = () => {
    this.setState({ isOrderCancelModalOpen: false })
  }

  _openOrderConfirmModal = (key) => {
    this.setState({ 
      isOrderConfirmModalOpen: true,
      orderKey: key 
    })
  }

  _closeOrderConfirmModal = () => {
    this.setState({ isOrderConfirmModalOpen: false })
  }

  refreshPage = () => {
    window.location.reload();
  }
}


const OrderInfoBox = compose(
  withRouter,
  withFirebase,
  inject('sessionStore'),
  observer,
)(OrderInfoBoxBase);

export default OrderInfoBox;