import React, { Component } from 'react';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import AppColors from '../../../theme/AppColors';
import '../../../static/css/style.css';

const Start_Button = styled(Button)({
    background: AppColors.WHITE,
    border: 0,
    borderRadius: 10,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.16)',
    height: 40,
    width: 130,
    color: AppColors.VARO_PURPLE,
    fontFamily: 'Poppins',
    fontSize: 22,
    fontWeight: 800,
    fontStretch: 'normal',
    letterSpacing: 'normal',
});

class StartButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <Start_Button variant="contained" color="primary">
          START!
        </Start_Button>
    );
  }
}

export default StartButton;