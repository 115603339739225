export function convertWeektoString(week) {
  switch(week) {
    case 4: 
      return "3-4";
    case 5:
      return "4-1";
    case 6:
      return "4-2";
    case 7:
      return "4-3";
    case 8:
      return "4-4";
    case 9:
      return "5-1";
    case 10:
      return "5-2";
    case 11:
      return "5-3";
    case 12:
      return "5-4";
    case 13:
      return "6-1";
    case 14:
      return "6-2";
    case 15:
      return "6-3";
    case 16:
      return "6-4";
    case 17:
      return "6-5";
    case 18:
      return "7-1";
    case 19:
      return "7-2";
    case 20:
      return "7-3";
    case 21:
      return "9-1";
    case 22:
      return "9-2";
    case 23:
      return "9-3";
    case 24:
      return "9-4";
    case 25:
      return "10-1";
    case 26:
      return "10-2";
    case "week1_1":
      return "09/01";
    case "week2_1":
      return "09/02";
    default: 
      return week;
  }
}

export function weekToDeliveryDate(week) {
  switch(week) {
    case "week1_1":
      return "2021년 09월 01일";
    case "week2_1":
      return "2021년 09월 02일";
    default: 
      return week;
  }
}

export function priceOfRecipe(recipe, number) {
  switch(recipe) {
    case "보호 삼이탕":
      return 7500 * number;
    case "납작당면 잡채덮밥":
      return 7200 * number;
    case "마파가지":
      return 7000 * number;
    case "새콤달콤 유자새싹파스타":
      return 7500 * number;
    case "버섯들깨크림파스타":
      return 7500 * number;
    case "매콤 단호박감자볶음탕":
      return 11000 * number;
    case "황금 궁중떡볶이":
      return 10000 * number;
    case "구수 시래기 감자탕":
      return 12000 * number;
    case "순두부찌개":
      return 12000 * number;
    case "모둠 버섯전골":
      return 14000 * number;
    case "양송이 알 아히요":
      return 12900 * number;
  }
}

export function basicOrderQuantity(recipe) {
  switch(recipe) {
    case "보호 삼이탕":
      return "1인분";
    case "납작당면 잡채덮밥":
      return "1인분";
    case "마파가지":
      return "1인분";
    case "새콤달콤 유자새싹파스타":
      return "1인분";
    case "버섯들깨크림파스타":
      return "1인분";
    case "매콤 단호박감자볶음탕":
      return "2인분";
    case "황금 궁중떡볶이":
      return "2인분";
    case "구수 시래기 감자탕":
      return "2인분";
    case "순두부찌개":
      return "2인분";
    case "모둠 버섯전골":
      return "2인분";
    case "양송이 알 아히요":
      return "2인분";
  }
}

export function getRecipeNames() {
  return ["보호 삼이탕", "납작당면 잡채덮밥", "마파가지", "새콤달콤 유자새싹파스타", "버섯들깨크림파스타",
      "매콤 단호박감자볶음탕", "황금 궁중떡볶이", "구수 시래기 감자탕", "순두부찌개", "모둠 버섯전골", "양송이 알 아히요"]
}