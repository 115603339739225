import SessionStore from './sessionStore';
import UserStore from './userStore';
import RemoteFlagsStore from './remoteFlagsStore';
import OrderStore from './orderStore';
import { createContext } from 'react';
import { create } from 'mobx-persist';
import localForage from 'localforage';

const hydrate = create({
  storage: localForage,
  jsonify: true
})

class RootStore {
  constructor() {
    this.orderStore = orderStore.setRootStore(this);
    this.sessionStore = new SessionStore(this);
    this.userStore = new UserStore(this);
    this.remoteFlagsStore = new RemoteFlagsStore(this);
  }
}
const orderStore = new OrderStore
hydrate("order", orderStore);

const rootStore = new RootStore(orderStore);

export default rootStore;