import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import Axios from 'axios';
import fileDownload from 'js-file-download';

import {db, auth, functions, storage, firebase} from '../../firebase';
import { withFirebase } from '../../components/firebase';
import * as ROUTES from '../../constants/routes';

import styled, { keyframes } from 'styled-components';
import varoTypography from '../../static/image/varoTypography.png';
import { makeStyles } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { nominalTypeHack } from 'prop-types';
import icon_checkbox_empty from '../../static/image/svg/icon_checkbox_empty.svg';
import icon_checkbox_fill from '../../static/image/svg/icon_checkbox_fill.svg';


const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: auto;
`

const LoginModal = styled.div`
  z-index: 10;
  width: 480px;
  height: 581px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  margin: 150px auto;
  padding: 20px;
  background: #FFFFFF;
  overflow: auto;
`

const SpanClose = styled.div`
  float: right;
  font-size: 25px;
`

const ModalContents = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const SingInIcon = styled.img`
  width: 150px;
  margin: 0 auto;
`

const LoginInput = styled.input`
  margin-top: 15px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const LoginMid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .autoLogin {
    font-size: 12px;
    color: #8d8d8d;
    line-height: 3;
  }
`

const Label = styled.div`
  font-size: 14px;
  color: #BBBBBB;
  margin-top: 10px;
  margin-bottom: -15px;
  margin-left: 4px;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
`;

//background-color: #F7D373;
const LoginBtn = styled.button`
  height: 40px;
  font-size: 14px;
  padding: 13px 30px;
  cursor: pointer;
  background-color: #000;
  color: #FFFFFF;
  line-height: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-radius: 3px;
  border-style: none;
`

const AutoLogin = styled.label`
  font-size: 12px;
  color: #8d8d8d;
  line-height: 3;
`

const LoginEnd = styled.div`
  text-align: center;
  font-size: 12px;
  .loginLine {
    a {
      color: black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .noUser {
    text-decoration: underline;
  }
`

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  term1: true,
  term2: false,
};

const SignUpPage = () => (
  <div>
    <SignUpModal />
  </div>
);

@observer
class SignUpModalBase extends Component {
  @observable loading

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      error: null,
      isModalOpen: true,
      term1: false,
      term2: false,
    }
    this.loading = false;
  }
 
  render() {
    const { username, email, passwordOne, passwordTwo, error, isModalOpen } = this.state;
    const { isOpen, close } = this.props;
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';
    const icon_term1 = this.state.term1 ? 
      <img src={icon_checkbox_fill} style={{ width: 18 }} /> :
      <img src={icon_checkbox_empty} style={{ width: 18 }} />
    const icon_term2 = this.state.term2 ? 
      <img src={icon_checkbox_fill} style={{ width: 18 }} /> :
      <img src={icon_checkbox_empty} style={{ width: 18 }} />
    
      return(
      isOpen ? 
      <Modal>
        <LoginModal>
          <SpanClose onClick={close} style={{cursor: 'pointer'}}>
            &times;
          </SpanClose>
          <ModalContents>
            <h3 style={{margin: 5, fontSize: 24, marginBotom: 20}}>
              VARO SIGN UP
            </h3>
            <Label>이름</Label>
            <LoginInput
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
              placeholder="Full Name"
            />
            <Label>이메일</Label>
            <LoginInput
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <Label>비밀번호</Label>
            <LoginInput
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
            <Label>비밀번호 확인</Label>
            <LoginInput
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
            />
            <div style={{height: 20}} />
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
              <div onClick={()=>{ this.setState({term1: !this.state.term1})}}>
                {icon_term1}
              </div>
              <div style={{width: 8}} />
              <p style={{margin: 0}}>서비스 이용약관에 동의합니다.</p>
              <div style={{width: 8}} />
              <Link to="/downloadfile/term.txt" target="_blank" download style={{textDecoration: 'none'}}>📄</Link>
            </div>
            <div style={{height: 10}} />
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <div onClick={()=>{ this.setState({term2: !this.state.term2})}}>
                {icon_term2}
              </div>
              <div style={{width: 8}} />
              <p style={{margin: 0}}>개인정보 취급방침에 동의합니다.</p>
              <div style={{width: 8}} />
              <Link to="/downloadfile/info.txt" target="_blank" download style={{textDecoration: 'none'}}>📄</Link>
            </div>
            <div style={{height: 10}} />
            <LoginBtn onClick={this.onSubmit} disabled={isInvalid}>
              가입하기
            </LoginBtn>
            {error && <p>{error.message}</p>}
          </ModalContents>
        </LoginModal>
      </Modal> : <div />
    )
  }

  openModal = () => {
    this.setState({ 
      email: "",
      password: "",
      error: {},
      isModalOpen: true
    });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };
 
  onSubmit = event => {
    const { username, email, passwordOne } = this.state;
    this.loading = true;
 
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(async authUser => {
        const user = authUser.user;
        await this.props.firebase.db.ref(`/user_sign_up/${user.uid}`)
          .update({ updatedAt: firebase.database.ServerValue.TIMESTAMP, username })
          .catch(console.log)
        await this.props.firebase.db.ref(`users/${user.uid}`)
          .update({ uid: user.uid, username, email })
          .catch(console.log)
        /*
        await firebase.firestore()
          .collection('users')
          .doc(user.uid)
          .set({
            username: username || user.uid,
          })
          .catch(error => console.log(error));
        */

        this.loading = false;
        this.setState({ ...INITIAL_STATE });
        this.props.close();
      })
      .catch(error => {
        this.setState({ error });
      });
    // prevents a reload of the browser which otherwise would be a natural behavior when using a submit in a form
    event.preventDefault();
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  download = (url, filename) => {
    Axios.get(url, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, filename);
    });
  }

}
 
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpModal = compose(
  withRouter,
  withFirebase,
)(SignUpModalBase);

export default SignUpModal;
 
export { SignUpModal, SignUpLink };