import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import {db, auth, functions, storage, firebase} from '../../firebase';

/*
const config = {
  apiKey: "AIzaSyCMUYDZ76sJC3rrIuFmtrGJB8fWJI5-Rrg",
  authDomain: "varo-web.firebaseapp.com",
  databaseURL: "https://varo-web.firebaseio.com",
  projectId: "varo-web",
  storageBucket: "varo-web.appspot.com",
  messagingSenderId: "830635391536",
  appId: "1:830635391536:web:95ab841e9ffb3b9dfccbf5",
  measurementId: "G-11JM1PR9LN"
};
*/
 
class Firebase {
  constructor() {
    this.functions = functions;
    this.storage = storage;
    this.auth = auth;
    this.db = db;
    this.auth.languageCode = 'ko';
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSendPasswordResetEmail = (email) => 
    this.auth.sendPasswordResetEmail(email);
  
  doSignOut = () => 
    this.auth.signOut();

  doPasswordReset = (email) => 
    this.auth.sendPasswordResetEmail(email);
 
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            // if (!dbUser.roles) {
            //  dbUser.roles = [];
            // }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });
    
  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');
}
 
export default Firebase;