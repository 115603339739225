import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { hasProvider } from '../../utils/id';
import { styled as mtstyled } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import AppColors from '../../../theme/AppColors';
import PersonIcon from '@material-ui/icons/Person';
import varoTypography from '../../../static/image/varoTypography.png';
import { makeStyles } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { nominalTypeHack } from 'prop-types';

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 10;
`

const LoginModal = styled.div`
  z-index: 10;
  width: 480px;
  height: 521px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  margin: 150px auto;
  padding: 20px;
  background: #FFFFFF;
`

const SpanClose = styled.div`
  float: right;
  font-size: 25px;
`

const ModalContents = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const SingInIcon = styled.img`
  width: 150px;
  margin: 0 auto;
`

const LoginIdInput = styled.input`
  margin-top: 30px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const LoginPwInput = styled.input`
  margin-top: 15px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const LoginMid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .autoLogin {
    font-size: 12px;
    color: #8d8d8d;
    line-height: 3;
  }
`
//background-color: #F7D373;
const LoginBtn = styled.button`
  height: 40px;
  font-size: 14px;
  padding: 13px 30px;
  cursor: pointer;
  background-color: #F7D373;
  color: #000000;
  line-height: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-radius: 3px;
  border-style: none;
`

const AutoLogin = styled.label`
  font-size: 12px;
  color: #8d8d8d;
  line-height: 3;
`

const LoginEnd = styled.div`
  text-align: center;
  font-size: 12px;
  .loginLine {
    a {
      color: black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .noUser {
    text-decoration: underline;
  }
`

const Login_Button = mtstyled(Button)({
  background: AppColors.WHITE,
  height: 60,
  width: 60,
  borderRadius: 30,
});

const useStyles = makeStyles((theme) => ({
  root: {
    background: AppColors.WHITE,
    width: 30,
    height: 30,
  },
}));

function LoginButton_test() {
  const classes = useStyles();

  return (
    <Login_Button className={classes.root} variant="text" color="primary">
      <PersonIcon style={{ fontSize: 24 }} />
    </Login_Button>
  );
}

const signInEmailErrorCodes = ['auth/invalid-email', 'auth/user-disabled', 'auth/user-not-found'];

@observer
class LoginButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: {},
      isModalOpen: true,
    };
  }

  openModal = () => {
    this.setState({ 
      email: "",
      password: "",
      error: {},
      isModalOpen: true
    });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };
/*
  handleLogin = async () => {
    this.setState({ error: {} });
    // await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL); // 브라우저 창이 닫히거나 React Native에서 활동이 폐기된 경우에도 상태가 유지됨을 나타냅니다. 이 상태를 삭제하려면 명시적으로 로그아웃해야 합니다. Firebase 인증 웹 세션은 단일 호스트 출처이며 단일 도메인의 경우에만 유지된다는 점에 유의하세요.
    await this.props.firebase.auth.signInWithEmailAndPassword(this.state.email, this.state.password)
    .then(res => {
      console.log(res)
    })
    .catch(async (error) => {
      if (error && error.code && signInEmailErrorCodes.includes(error.code)) {
        this.setState({
          error: { ...this.state.error, email: error }
        })
      } else {
        if (error.code === 'auth/wrong-password') {
          // if the email has a facebook provider, try logging as a facebook account
          const providers = await this.props.firebase.auth.fetchSignInMethodsForEmail(this.state.email)
          if (hasProvider(providers, 'facebook.com')) {
            this.handleFacebookLogin()
          } else {
            this.setState({
              error: { ...this.state.error, password: error }
            })
          }
        } else {
          this.setState({
            error: { ...this.state.error, password: error }
          })
        }
      }
    });
  };
*/
  _renderSignInModal = () => {
    const { email, password } = this.state
    return (
      <Modal>
        <LoginModal>
          <SpanClose onClick={this.closeModal}>
            &times;
          </SpanClose>
          <ModalContents>
            <img src={varoTypography} style={{ marginBottom: 10, width: 80, alignSelf: 'center'}} /> 
            <h3 style={{margin: 5, fontSize: 20}}>
              SIGN IN
            </h3>
            <LoginIdInput
              name="email"
              type="text"
              placeholder="아이디"
              value={email}
              onChange={this.changeEmail}
            />
            <LoginPwInput
              name="password"
              type="password"
              placeholder="비밀번호"
              value={password}
              onKeyDown={this.handleKeyPress}
              onChange={this.changePassword}
            />
            <LoginMid>
              <AutoLogin for="hint">
                {" "}
                <input type="checkbox" id="hint" /> 로그인 유지하기
              </AutoLogin>
              <AutoLogin>아이디/비밀번호 찾기</AutoLogin>
            </LoginMid>
            <LoginBtn onClick={this.handleLogin}>
              로그인
            </LoginBtn>
            <LoginEnd>
              <div className="loginLine">
                회원이 아니신가요? <Link to="/login/sign_up">회원가입</Link>
              </div>
              <div style={{height: 10}} />
              {/*<div className="noUser">비회원 주문 조회</div>*/}
            </LoginEnd>
          </ModalContents>
        </LoginModal>
      </Modal>
    );
  }

  render() {
    return (
      <div style={{ marginLeft: 5 }}>
        <div onClick={this.openModal}>
          <LoginButton_test />
        </div>
        {!this.state.isModalOpen? this._renderSignInModal() : null}
      </div>
    )
  }

  changeEmail = (e) => {
    this.setState({
      email: e.target.value,
      error: {}
    });
  }

  changePassword = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  handleKeyPress = (e) => {
    if(e.keyCode == 13) {
      this.handleLogin();
    }
  }

  goToForgotPswd = () => {
    console.log('forgot password')
  }
}

export default LoginButton;
