import React, { 
  Component,
} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import '../../static/css/style.css';
import * as ROUTES from '../../constants/routes';
import Img_home_samii_mobile from '../../static/image/Img_home_samii_mobile.jpg';
import Img_home_samii from '../../static/image/Img_home_samii.jpg';
import HMB from '../../static/image/HMB.jpeg';

import Img_home_1 from '../../static/image/img_main_1.jpg';
import Img_home_2 from '../../static/image/img_main_2.jpg';
import Img_home_3 from '../../static/image/img_main_3.jpg';
import Img_home_4 from '../../static/image/img_main_4.jpg';
import Img_home_5 from '../../static/image/img_main_5.jpg';
import Img_home_6 from '../../static/image/img_main_6.jpg';
import Img_home_7 from '../../static/image/img_main_7.jpg';
import Img_home_8 from '../../static/image/img_main_8.jpg';
import Img_home_9 from '../../static/image/img_main_9.jpg';

import Icon_EE8D85 from '../../static/recipe_icons/Icon_EE8D85.png';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: NotoSansKR;
  padding-top: 60px;
  overflow-y: hidden;
`;

const ImageWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-contents: flex-start;
  background-image: url(${props => props.src});
  background-size: cover;
`;
// f7d373

const VaroText = styled.p`
  font-size: ${props => props.font_size};
  font-weight: 600;
  font-family: NotoSansCJKkr-Regular;
  margin: 0;
  padding: 0;
  color: #222;
`

const TextH1 = styled.h1`
  font-family: NotoSansCJKkr-Black;
  margin: 0;
  color: #FFF;
  font-weight: bold;
  @media(min-width: 1300px){
    font-size: 40px;
  }
  @media(max-width: 1300px){
    font-size: 34px;
  }
`

const TextH2 = styled.h1`
  font-family: NotoSansCJKkr-Medium;
  margin-top: 30px;
  color: #FFF;
  font-weight: 500;
  letter-spacing: -1px;
  @media(min-width: 1300px){
    font-size: 32px;
  }
  @media(max-width: 1300px){
    font-size: 26px;
  }
`

const ButtonText = styled.h1`
  font-family: NotoSansCJKkr-black;
  margin: 0;
  color: #FFF;
  font-weight: bold;
  @media(min-width: 1300px){
    font-size: 34px;
  }
  @media(max-width: 1300px){
    font-size: 28px;
  }
`

const MobileTextH1 = styled.h1`
  font-family: NotoSansCJKkr-Black;
  margin: 0;
  font-weight: bold;
  font-size: 20px;
`

const MobileTextH2 = styled.h1`
  font-family: NotoSansCJKkr-Medium;
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -1px;
`

const MobileButtonText = styled.h1`
  font-family: NotoSansCJKkr-black;
  margin: 0;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
`

const StartButton = styled.div`
  margin-top: 25px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  cursor: pointer;
`

const MainTitileAreaWrapper = styled.div`
  position: relative;
  width: 50vw;
  min-width: 500px;
  height: 50vh;
  margin-top: 20vh;
  align-self: flex-start;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.bigscreen`
    ${(WithVaroText)} {
      font-size: 20px;
    }
  `}
  ${({ theme }) => theme.mediumscreen`
    ${(WithVaroText)} {
      font-size: 17px;
    }
  `}
  ${({ theme }) => theme.desktop`
    ${(WithVaroText)} {
      font-size: 14px;
    }
    ${(WithVaroBox)} {
      height: 50px;
      min-width: 400px;
    }
    ${(WithVaroInnerBox)} {
      height: 40px;
      width: 80px;
    }
  `}
`

const MainTitleText = styled.h1`
  font-size: 60px;
  font-weight: normal;
  font-family: NotoSansCJKkr-Black;
  margin: 5px;
  ${({ theme }) => theme.bigscreen`
    font-size: 60px;
  `}
  ${({ theme }) => theme.mediumscreen`
    font-size: 50px;
  `}
  ${({ theme }) => theme.desktop`
    font-size: 45px;
  `}
`

const MainSubTitleText = styled.h1`
  font-size: 30px;
  font-weight: normal;
  font-family: NotoSansKR;
  margin: 5px;
  ${({ theme }) => theme.bigscreen`
    font-size: 30px;
  `}
  ${({ theme }) => theme.mediumscreen`
    font-size: 25px;
  `}
  ${({ theme }) => theme.desktop`
    font-size: 20px;
  `}
`

const WithVaroBox = styled.div`
  margin-top: 40px;
  width: 40vw;
  max-width: 510px;
  height: 60px;
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 35px;
  box-shadow: 2px 2px 6px 0px rgba(50, 50, 50, 0.15);
  z-index: 30;
`

const WithVaroText = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  font-family: NotoSansKR;
  margin-left: 20px;
`

const WithVaroInnerBox = styled.div`
  width: 100px;
  height: 50px;
  border-radius: 25px;
  background-color: #999;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 11px 0px rgba(50, 50, 50, 0.15);
`

const ImageVertical = styled.div`
  position: absolute;
  top: -10vw;
  left: 62vw;
  width: 35vw;
  height: 56vw;
  max-height: 80vh;
  border-radius: 40vw;
  background-image: url(${props => props.imageSource});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 20;
  ${({ theme }) => theme.desktop`
    left: 62vw;
    height: 56vw;
  `}
`

class MainBoxBase extends Component {
  constructor(props) {
    super(props);
    this.data = [, ,];
    this.state = {
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  isBigScreen() {
    return this.state.height > 1000? true : false
  }

  render() {
    const { width, height } = this.state;
    if (width < 800) {
      return (
        <Wrapper style={{height: '70vh'}}>
          <ImageWrapper src={Img_home_samii_mobile} style={{objectFit: 'cover'}}>
            <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '5vw', textAlign: 'left'}}>
              <MobileTextH1>
                보호삼이탕
              </MobileTextH1>
              <MobileTextH2>
                각종 한약재와 버섯의 향연.
              </MobileTextH2>
              <MobileTextH2 style={{margin: 0}}>
                내 몸을 위한 한 끼를 즐기세요
              </MobileTextH2>
            </div>
          </ImageWrapper>
        </Wrapper>
      )
    } else {
      return (
        <Wrapper >
          <div style={{position: 'relative', width: '100%', height: '70vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%', backgroundImage: `url(${Img_home_3})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
            <div style={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%', backgroundImage: `url(${Img_home_5})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
            <div style={{position: 'absolute', width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', top: '15vh', left: '20%'}}>
              
              <MainTitleText style={{color: '#FFF'}}>모두를 위한 선택</MainTitleText>
              <MainSubTitleText style={{color: '#FFF'}}>가족, 환경, 건강, 동물</MainSubTitleText>
              <div 
                onClick={() => {window.location.href = "https://smartstore.naver.com/spirit_varo";}}
                style={{width: 160, height: 50, marginTop: '5vh', borderStyle: 'solid', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#dbdbdb', 
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
              >
                <p style={{fontSize: 16, margin: 0, fontFamily: 'NotoSansKR', fontWeight: 'normal'}}>Shop Now</p>
              </div>
            </div>
          </div>

          <div style={{marginTop: 100, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <p style={{fontSize: 20, fontFamily: 'NotoSansKR', fontWeight: 'normal', marginBottom: 20}}>
              Our Service
            </p>
            <div style={{width: '100%', paddingLeft: 50, paddingRight: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{width: 'calc(33.3% - 15px)', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', height: '35vw', backgroundColor: 'blue', backgroundImage: `url(${Img_home_1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
                <div style={{height: 15}} />
                <h4>Vegan MealKit</h4>
                <p>다채롭고 신선하고 맛있는 밀키트를 온라인으로 판매하고 있습니다.</p>
              </div>
              <div style={{width: 'calc(33.3% - 15px)', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', height: '35vw', backgroundColor: 'green', backgroundImage: `url(${Img_home_7})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
                <div style={{height: 15}} />
                <h4>Vegan MealKit</h4>
                <p>다채롭고 신선하고 맛있는 밀키트를 온라인으로 판매하고 있습니다.</p>
              </div>
              <div style={{width: 'calc(33.3% - 15px)', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', height: '35vw', backgroundColor: 'pink', backgroundImage: `url(${Img_home_9})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
                <div style={{height: 15}} />
                <h4>Vegan MealKit</h4>
                <p>다채롭고 신선하고 맛있는 밀키트를 온라인으로 판매하고 있습니다.</p>
              </div>
            </div>
          </div>
            
          <div style={{marginTop: 100, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <p style={{fontSize: 20, fontFamily: 'NotoSansKR', fontWeight: 'normal', marginBottom: 20}}>
              Our Missions
            </p>
            <div style={{width: '100%', paddingLeft: 50, paddingRight: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{width: 'calc(50% - 15px)', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', height: '30vw', backgroundColor: 'blue', backgroundImage: `url(${Img_home_2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
                <div style={{height: 15}} />
                <h4>Vegan MealKit</h4>
                <p>다채롭고 신선하고 맛있는 밀키트를 온라인으로 판매하고 있습니다.</p>
              </div>
              <div style={{width: 'calc(50% - 15px)', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', height: '30vw', backgroundColor: 'green', backgroundImage: `url(${Img_home_8})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
                <div style={{height: 15}} />
                <h4>Vegan MealKit</h4>
                <p>다채롭고 신선하고 맛있는 밀키트를 온라인으로 판매하고 있습니다.</p>
              </div>
            </div>
          </div>
        </Wrapper>
      )
    }
  }
}

const MainBox = compose(
  withRouter,
)(MainBoxBase);

export default MainBox;