import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import Header from '../../components/base/Header';
import Header_Mobile from '../../components/base/mobile/header_mobile';
import Footer from '../../components/base/Footer';
import { withFirebase } from '../../components/firebase';

import MENU_PLATE from '../../components/home/menu_plate';
import sep_home from '../../static/image/svg/sep_home.svg';
import sep_home_inverse from '../../static/image/svg/sep_home_inverse.svg';
import { MENU_INFO } from '../order/menu_info_constants/menu_info';
import Img_info_1 from '../../static/image/Img_info_AD220B.jpg';
import Img_info_2 from '../../static/image/Img_info_8ECEE0.jpg';
import Img_info_3 from '../../static/image/Img_info_9D1E14.jpg';
import Img_info_4 from '../../static/image/Img_info_EE8D85.jpg';
import * as ROUTES from '../../../src/constants/routes';

/*
const WEEK1 = MENU_INFO[1]
const WEEK1_INFO = {
  1: Img_info_EFE1A7,
  2: Img_info_EE8D85,
  3: Img_info_F5B076
}
*/

const WEEK4 = MENU_INFO[26]
const WEEK4_INFO = { 
  1: Img_info_1,
  2: Img_info_2,
  3: Img_info_3,
  4: Img_info_4
}


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: IBMPlexSansKR-Regular;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 0px;
`;

const Modal = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ModalContent = styled.div`
  z-index: 10;
  width: 80vw;
  height: 80vh;
  max-width: 800px;
  position: relative;
  box-sizing: border-box;
  overflow: auto;
  background-color: #FFF;
`

const SpanClose = styled.div`
  float: right;
  margin-right: 10px;
  font-size: 25px;
`

const VaroText = styled.p`
  font-size: ${props => props.font_size};
  font-weight: 600;
  font-family: 'IBMPlexSansKR-Medium';
  margin: 0;
  padding: 0;
  color: #222;
  @media (min-width: 1300px) {
    font-size: 1.3vw;
	}
  @media (max-width: 1300px) {
    font-size: 1.6vw;
  }
  @media (max-width: 800px) {
    font-size: 12px;
  }
`

const HeadingVaroText = styled.p`
  font-size: 14px;
  margin: 0;
  @media (min-width: 1300px) {
    font-size: 22px;
	}
  @media (max-width: 1300px) {
    font-size: 16px
  }
`

const HeadingTitleText = styled.p`
  font-size: 20px;
  margin: 0;
  margin-top: -5px;
  width: 50vw;
  font-weight: 600;
  @media (min-width: 1300px) {
    font-size: 30px;
	}
  @media (max-width: 1300px) {
    font-size: 22px
  }
`

const TextH3 = styled.div`
  font-size: 14px;
  margin: 0;
  @media (min-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1300px) {
    font-size: 16px
  }
  @media (max-width: 800px) {
    font-size: 11px
  }
`;

const TextH4 = styled.div`
  font-size: 14px;
  margin: 0;
  @media (min-width: 1300px) {
    font-size: 16px;
  }
  @media (max-width: 1300px) {
    font-size: 14px
  }
  @media (max-width: 800px) {
    font-size: 10px
  }
`;

const TextH5 = styled.div`
  font-size: 14px;
  margin: 0;
  @media (min-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1300px) {
    font-size: 10px
  }
  @media (max-width: 800px) {
    font-size: 8px
  }
`;

class MenuDetailScreenBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      isOrderModalOpen_1: false,
      isOrderModalOpen_2: false,
      isOrderModalOpen_3: false,
      isOrderConfirmModalOpen: false,
      menuShow: 1,
      selectImage: '',
      menu1_order: 0,
      menu2_order: 0,
      menu3_order: 0,
      menu4_order: 0,
      name: "",
      phone: "",
      zoneCode: "",
      address: "",
      deliveryRequest: "",
      fullAddress: "",
      isDaumPost: false,
      addressPick: "",
      isRequestReceipt: false,
      receiptPhone: "",
      discountCode: "",
      discountRate: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.IMP.init("imp87339235");
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  _clickMenuImage = (menu) => {
    this.setState({ menuShow: menu })
  }

  _clickImage = (n) => {
    this.setState({ selectImage: n })
  }

  render() {
    const { width, selectImage } = this.state;

    return (
      <Wrapper>
        { width>800? <Header /> : <Header_Mobile />}
        <ContentWrapper>
          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 80}}>
            <HeadingVaroText>VARO</HeadingVaroText>
            <HeadingTitleText>이번주 메뉴</HeadingTitleText>
          </div>
        </ContentWrapper>
        <ContentWrapper>
          <div style={{height: 10}} />
          <img src={sep_home_inverse} style={{width: '100vw', marginBottom: -2, backgroundColor: '#F9EBB3'}} />
          <div style={{width: '100vw', paddingTop: width>800?100:50, paddingBottom: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F9EBB3'}}>
            <div style={{width: width>800?'70vw':'80vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <div onClick={() => this._clickImage(1)} style={{cursor: "pointer"}}>
                <MENU_PLATE 
                  plate_width={width>800?width * 0.6 * 0.25:width * 0.6 * 0.28}
                  menu_image={WEEK4[1].icon}
                  menu_title={WEEK4[1].title}
                  menu_colorCode={WEEK4[1].color_code}
                />
              </div>
              <div onClick={() => this._clickImage(2)} style={{cursor: "pointer"}}>
                <MENU_PLATE 
                  plate_width={width>800?width * 0.6 * 0.25:width * 0.6 * 0.28}
                  menu_image={WEEK4[2].icon}
                  menu_title={WEEK4[2].title}
                  menu_colorCode={WEEK4[2].color_code}
                />
              </div>
              <div onClick={() => this._clickImage(3)} style={{cursor: "pointer"}}>
                <MENU_PLATE 
                  plate_width={width>800?width * 0.6 * 0.25:width * 0.6 * 0.28}
                  menu_image={WEEK4[3].icon}
                  menu_title={WEEK4[3].title}
                  menu_colorCode={WEEK4[3].color_code}
                />
              </div>
              <div onClick={() => this._clickImage(4)} style={{cursor: "pointer"}}>
                <MENU_PLATE 
                  plate_width={width>800?width * 0.6 * 0.25:width * 0.6 * 0.28}
                  menu_image={WEEK4[4].icon}
                  menu_title={WEEK4[4].title}
                  menu_colorCode={WEEK4[4].color_code}
                />
              </div>
            </div>
            <div style={{height: width>800?'3vh':'5vh'}} />
            <div onClick={() => this.props.history.push(ROUTES.ORDER_STEP2)}
              style={{display: 'flex', flexDirection: 'column', width: width>800?'20vw':'35vw', height: width>800?'5vw':'8.8vw', borderRadius: width>800?'3vw':'4.4vw',
              justifyContent: 'center', backgroundColor: '#FFF', boxShadow: '2px 2px 4px 1px rgba(0,0,0,0.2)', cursor: 'pointer'}}>
              <VaroText style={{margin: 0, padding: 0, fontWeight: 500, textAlign: 'center', alignSelf: 'center', color: '#000'}}>
                {'주문 하러가기 >>'}
              </VaroText>
            </div>
            <div style={{height: width>800?'3vh':'3vh'}} />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: width<800? '80%':null}}>
              <TextH3 style={{fontWeight: 'bold'}}>・ 위의 메뉴판을 클릭하시면 자세한 내용을 확인하실 수 있습니다.</TextH3>
              <div style={{height: 8}} />
              <TextH4>・ 10월 7일 ~ 10월 12일 오후 1시 주문 가능합니다.</TextH4>
              <div style={{height: 8}} />
              <TextH4>・ 발송일은 10월 13일입니다. 익일 배송을 원칙으로하며, 충분한 보냉포장을 하여 발송합니다.</TextH4>
              <div style={{height: 8}} />
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                <TextH4>・ 배송비는 기본 3,500원이며 주문금액 35,000원부터 무료 배송을 해드립니다. 택배요금 상승과 메뉴 조정 이후 변동이 생긴점 양해 부탁드립니다.</TextH4>
              </div>
            </div>
          </div>
          <div style={{height: 10}} />
        </ContentWrapper>
        { selectImage?
          <Modal>
            <ModalContent>
              <SpanClose onClick={()=>{this.setState({selectImage: ""})}} style={{cursor: 'pointer'}}>
                &times;
              </SpanClose>
              <img src={WEEK4_INFO[selectImage]} style={{width: '100%'}}/>
            </ModalContent>
          </Modal> : null
        }
        <Footer />
      </Wrapper>
    )
  }
}

const MenuDetailScreen = compose(
  withRouter,
  withFirebase,
  inject('sessionStore'),
  observer,
)(MenuDetailScreenBase);

export default MenuDetailScreen;