import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';
import styled from 'styled-components';
import varo_typo from '../../static/image/svg/varo_typo.svg'
import '../../static/css/style.css';

import { FiUser } from 'react-icons/fi';
import SignUpModal from '../../page/login/signUp';
import SignInModal from '../../page/login/signIn';
import FindPasswordModalBase from '../../page/login/find_password';
import NoticeModal from './noticeModal';

const HEADER_SCROLL_TOP_OPACITY = 1.0;

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	z-index: 10;
  font-family: NotoSansCJKkr-Regular;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
	align-items: center;
	justify-content: flex-start;
  background-color: #FFF;
  padding-left: 4%;
  padding-right: 4%;
  height: 70px;
`;

const LOGO = styled.img`
  margin-bottom: 0;
  margin-top: 5px;
  @media(min-width: 1300px){
    height: 23px;
  }
  @media(max-width: 1300px){
    height: 23px;
  }
`

const NavSectionRight = styled.div`
	display: flex;
	flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 5%;
`;

const LogoContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  @media (min-width: 700px) {
    margin-top: -1px;
  }
`;

const BrandName = styled.div`
  font-size: 32px;
  font-weight: 500;
	color: ${ props => props.theme.color.BLACK};
  font-family: NotoSansCJKkr-Regular;
  @media (min-width: 700px) {
    margin-top: 6px;
	}
  @media (max-width: 700px) {
    margin-top: 6px;
	}
`;

const NavText = styled.h3`
  color: ${ props => props.theme.color.BLACK};
  font-family: NotoSansCJKkr-Regular;
  margin: 10px;
  margin-top: 15px;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 500;

  @media(min-width: 1300px){
    font-size: 1.2rem;
  }
  @media(max-width: 1300px){
    font-size: 1.2rem;
  }
`;

const NavSepPoint = styled.div`
  background-color: #000;
  margin-left: 20px;
  margin-right: 20px;
  width: 2px;
  height: 2px;
  border-radius: 3px;
  margin-top: 4px;
  @media(max-width: 1300px){
    width: 2px;
    height: 2px;
    border-radius: 2px;
    margin-top: 3.5px;
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      onTopScroll: true,
      headerBarOpacity: HEADER_SCROLL_TOP_OPACITY,
      userIconBgColor: '#FFFFFF',
      isSignInModalOpen: false,
      isSignUpModalOpen: false,
      isFindPasswordModalOpen: false,
      isNoticeModalOpen: false,
    };
    this.resize = this.resize.bind(this);
  }

  resize() {
    this.setState({ hidden: !(window.innerWidth >= 700) });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    /*
    window.onscroll = () => {
      const { onTopScroll } = this.state;
      if (!onTopScroll && window.scrollY <= 700) {
        this.setState({
          onTopScroll: true
        });
      } else if (onTopScroll && window.scrollY > 700) {
        this.setState({
          onTopScroll: false
        });
      }
    };
    /* Header Opacity
    window.onscroll = () => {
      const { headerBarOpacity } = this.state;
      if (headerBarOpacity <= HEADER_SCROLL_TOP_OPACITY && window.scrollY > 700) {
        this.setState({
            headerBarOpacity: 1.0,
        });
      } else if (headerBarOpacity >= 1.0 && window.scrollY <= 700) {
        this.setState({
            headerBarOpacity: HEADER_SCROLL_TOP_OPACITY,
        });
      }
    };
    */
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
    window.onscroll = null;
  }

  render() {
    const { headerBarOpacity, onTopScroll } = this.state;
    return (
      <Wrapper>
        <ContentContainer opacity={headerBarOpacity}>
          <div style={{flex: 1}} />
          <NavLink exact activeClassName="active" to="/" style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <LogoContainer>
              <LOGO src={varo_typo} /> 
            </LogoContainer>
          </NavLink>

          <NavSectionRight>
            <NavText onClick={() => alert("준비중입니다.")}>
              바로에 대해
            </NavText>
            <NavSepPoint />
            <NavLink exact activeClassName="active" to="/order/select" style={{ textDecoration: 'none' }}>
              <NavText>
                메뉴
              </NavText>
            </NavLink>
            <NavSepPoint />
            <NavLink exact activeClassName="active" to="/order/select" style={{ textDecoration: 'none' }}>
              <NavText>
                주문하기
              </NavText>
            </NavLink>
            {/* <button onClick={this._clickUserIcon} 
              style={{ backgroundColor: '#FFF', borderStyle: 'none', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
              <FiUser size={'22px'} style={{ marginTop: 6, alignSelf: 'center'}} />
            </button> */}
          </NavSectionRight>
        </ContentContainer>
        <SignInModal isOpen={this.state.isSignInModalOpen} close={this.closeSignInModal} moveToSignUp={this.moveToSignUp} />
        <SignUpModal isOpen={this.state.isSignUpModalOpen} close={this.closeSignUpModal} />
        <FindPasswordModalBase isOpen={this.state.isFindPasswordModalOpen} close={this.closeFindPasswordModal} />
        <NoticeModal isOpen={this.state.isNoticeModalOpen} close={this.closeNoticeModal} 
          title={"비밀번호 재설정 바로 안내"}
          notice1={"입력하신 이메일로 비밀번호 재설정 메일이 전송되었습니다. 메일을 확인하시고 비밀번호를 재설정하여 주시기 바랍니다."} />
        {/*<MyPageModal isOpen={this.state.isMyPageModalOpen} close={this.closeMyPageModal} />*/}
      </Wrapper>
    );
  }

  _clickUserIcon = () => {
    if (this._isUserLoggedIn()) {
      this.props.history.push(ROUTES.MY_PAGE);
    } else {
      this.setState({ isSignInModalOpen: true });
    }
  }

  _isUserLoggedIn = () => {
    return this.props.sessionStore.authUser
  }

  closeSignInModal = (respond) => {
    if (respond === "close") {
      this.setState({ isSignInModalOpen: false })
    } else if (respond === "findPassword") {
      this.setState({ 
        isSignInModalOpen: false,
        isFindPasswordModalOpen: true
       });
    }
  }

  closeSignUpModal = () => {
    this.setState({ isSignUpModalOpen: false });
  }

  closeFindPasswordModal = (respond) => {
    if (respond === "close") {
      this.setState({ isFindPasswordModalOpen: false });
    } else if (respond === "sendPasswordResetEmail") {
      this.setState({
        isFindPasswordModalOpen: false,
        isNoticeModalOpen: true
      })
    }
  }

  closeNoticeModal = () => {
    this.setState({ isNoticeModalOpen: false });
  }

  moveToSignUp = () => {
    this.setState({ 
      isSignInModalOpen: false,
      isSignUpModalOpen: true
    });
  }
}

export default compose(
  inject('sessionStore'),
  observer,
  withRouter
)(Header);
//export default Header;
