import jsCookie from 'js-cookie';
import { camelizeKeys } from 'humps';

async function setAuthUser(authStore, user) {
  if (user) {
    const userSnap = (await this.props.firebase.db.ref('users/' + user.uid).once('value')).val();
    if (userSnap) {
      authStore.user = camelizeKeys(userSnap);
      jsCookie.set('currentUser', camelizeKeys(authStore.user));

      if (!userSnap.invite_link) {
        this.props.firebase.db.ref('users/' + user.uid + '/invite_login').set(Date.now());
      }
    } else {
      authStore.user = null;
      jsCookie.remove('currentUser');
    }
  } else {
    authStore.user = null;
    jsCookie.remove('currentUser');
  }
}

export default setAuthUser
