const AppColors = {
    PRIMARY: '#8b3eeb',
    SECONDARY: '#fe6161',
    TERTIARY: '#78d9e9',
    HOVER: '#5b1ca8',
    BLACK: '#303133',
    GREY: '#8d8d8f',
    PALE_GREY: '#f5eefc',
    LIGHT_GREY: '#dadada',
    DARK_GREY: '#333333',
    WHITE: '#ffffff',
    PRESSED: '#c597ff',
    SUCCESS: '#44a45f',
    WARNING: '#fceb3b',
    ERROR: '#e6173e',
    
    BLUE_GROTTO: '#189AB4',
    NAVY_BLUE: '#05445E',
    // HAEM's COLOR
    NEON_GREEN: '#62ffcc',
    HAEM_PALE_PINK: '#ECA49B',
    HAEM_PALE_SKYBLUE: '#CCE5E5',
    HAEM_PALE_YELLOW: '#FCBE37',
    
    // Varo's COLOR
    VARO_GREEN: '#709673',
    VARO_TRS_GREEN: 'rgba(112, 150, 115, 0.3)',
    VARO_ABOUT_GREEN: '#708A4E',
    VARO_PURPLE: '#b28bbd',
    VARO_LIGHT_GREY: 'rgba(159, 159, 159, 0.2)',
    VARO_PALE_GREY: '#6B6B6B',
    VARO_GREY_WHITE: '#fcfcfc',
    VARO_BLUE_WHITE: '#f2fffd',
    VARO_PURPLE_WHITE: '#EEEAFD',
    VARO_GREEN_WHITE: '#EAFDEA',

    // Log
    LOG_DEBUG: '#828282',
    LOG_INFO: '#333333',
    LOG_WARNING: '#f6981d',
    LOG_ERROR: '#e7711b',
    LOG_CRITICAL: '#d14737',

  FUNDING: {
    MAIN: '#edd973',
  }
    
  };
  
  export default AppColors;
  