import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';

import {db, auth, functions, storage, firebase} from '../../firebase';
import { withFirebase } from '../../components/firebase';
import * as ROUTES from '../../constants/routes';
import DaumPostCode from 'react-daum-postcode';

import styled, { keyframes } from 'styled-components';
import varoTypography from '../../static/image/varoTypography.png';
import { makeStyles } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { nominalTypeHack } from 'prop-types';

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: auto;
`

const LoginModal = styled.div`
  z-index: 10;
  width: 500px;
  height: 561px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  margin: 150px auto;
  margin-bottom: 50px;
  padding: 20px;
  background: #FFFFFF;
  overflow: auto;
`

const SpanClose = styled.div`
  float: right;
  font-size: 25px;
`

const ModalContents = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const InputBox = styled.input`
  margin-top: 15px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const TextBox = styled.div`
  margin-top: 15px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const Label = styled.div`
  font-size: 14px;
  color: #BBBBBB;
  margin-top: 10px;
  margin-bottom: -15px;
  margin-left: 4px;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
`;

const LoginMid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .autoLogin {
    font-size: 12px;
    color: #8d8d8d;
    line-height: 3;
  }
`
//background-color: #F7D373;
const LoginBtn = styled.button`
  height: 40px;
  font-size: 14px;
  padding: 13px 30px;
  cursor: pointer;
  background-color: #000;
  color: #FFF;
  line-height: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-radius: 3px;
  border-style: none;
`

const AutoLogin = styled.label`
  font-size: 12px;
  color: #8d8d8d;
  line-height: 3;
`

const LoginEnd = styled.div`
  text-align: center;
  font-size: 12px;
  .loginLine {
    a {
      color: black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .noUser {
    text-decoration: underline;
  }
`

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

@observer
class PhoneModalBase extends Component {
  @observable loading

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      address: "",
      zoneCode : "",
      fullAddress : "",
      isDaumPost : false,
      isRegister : false,
      register: [],
      error: null,
    }
    this.loading = false;
  }

  handleOpenPost = () => {
    this.setState({
        isDaumPost : true
    })
  }

  // postcode
  handleAddress = (data) => {
    let AllAddress = data.address;
    let extraAddress = ''; 
    let zoneCodes = data.zonecode;
    
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      AllAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    this.setState ({
        fullAddress: AllAddress,
        zoneCode : zoneCodes
    })
  }
 
  render() {
    const { name, phone, address, isDaumPost, fullAddress, zoneCode, isRegister } = this.state;
    const { isOpen, close } = this.props;
    const width = 500;
    const height = 450;
    const modalStyle = {
        position: "absolute",
        marginTop: 465,
        left: "0x",
        zIndex: "100",
        border: "1px solid #000000",
        overflow: "hidden",
        alignSelf: 'center',
        justifySelf: 'center'
    }

    return(
      isOpen ? 
      <Modal>
        <LoginModal>
          <SpanClose onClick={() => {close("close")}} style={{cursor: 'pointer'}}>
            &times;
          </SpanClose>
          <ModalContents>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <h3 style={{margin: 5, fontSize: 24, marginBottom: 20, fontFamily: 'IBMPlexSansKR-Regular', fontWeight: '600'}}>
                주소 입력
              </h3>
            </div>
            <Label>받으시는 분 이름</Label>
            <InputBox
              name="name"
              value={name}
              onChange={this.onChange}
              type="text"
              placeholder="이름을 입력해주세요"
            />
            <Label>연락처</Label>
            <InputBox
              name="phone"
              value={phone}
              onChange={this.onChange}
              type="text"
              placeholder="-없이 입력해주세요"
            />
            <Label>주소</Label>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <TextBox style={{width: '65%'}}>
                {zoneCode}
              </TextBox>
              <div style={{width: '5%'}} />
              <TextBox onClick={this.handleOpenPost} 
                style={{width: '30%', backgroundColor: '#000', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <p style={{maring: 0, alignSelf: 'center', justifyContent: 'center', color: '#FFF'}}>
                  우편번호 검색
                </p>
              </TextBox>
            </div>
            {
              isDaumPost ?
                <DaumPostCode
                  onComplete={this.handleAddress}
                  autoClose
                  width={width}
                  height={height}
                  style={modalStyle}
                  isDaumPost={isDaumPost}
                />
              : null
            }
            <TextBox>{fullAddress}</TextBox>
            <InputBox
              name="address"
              value={address}
              onChange={this.onChange}
              type="text"
              placeholder="상세 주소를 적어주세요"
              style={{textAlign: 'center'}}
            />
            <LoginBtn onClick={this.onSubmit}>
              등록
            </LoginBtn>
          </ModalContents>
        </LoginModal>
      </Modal> : <div />
    )
  }
 
  onSubmit = (event) => {
    const { name, phone, address, isDaumPost, fullAddress, zoneCode, isRegister } = this.state;
    const { close } = this.props;

    this.loading = true
    this.props.firebase.db.ref(`users/${this.props.sessionStore.authUser.uid}/address/${name}`)
    .update({ 
      name: name,
      phone: phone,
      zoneCode: zoneCode,
      address1: fullAddress,
      address2: address
    })
    .catch(console.log)
    .then(() => {
      close();
    })
    this.loading = false
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
}

const AddressModal = compose(
  withRouter,
  withFirebase,
  inject('sessionStore'),
  observer,
)(PhoneModalBase);

export default AddressModal;

