import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';

import {db, auth, functions, storage, firebase} from '../../firebase';
import { withFirebase } from '../../components/firebase';
import * as ROUTES from '../../constants/routes';

import styled, { keyframes } from 'styled-components';

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 10;
`

const LoginModal = styled.div`
  z-index: 10;
  width: 480px;
  height: 441px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  margin: 150px auto;
  padding: 20px;
  background: #FFFFFF;
  overflow: auto;
  border-radius: 5px;
`

const SpanClose = styled.div`
  float: right;
  font-size: 25px;
`

const ModalContents = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const LoginInput = styled.input`
  margin-top: 15px;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 9px 12px;
  outline: none;
  box-sizing: border-box;
`

const LoginMid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .autoLogin {
    font-size: 12px;
    color: #8d8d8d;
    line-height: 3;
    cursor: pointer;
  }
`
//background-color: #F7D373;
const LoginBtn = styled.button`
  height: 40px;
  font-size: 14px;
  padding: 13px 30px;
  cursor: pointer;
  background-color: #000;
  color: #FFFFFF;
  line-height: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  border-radius: 3px;
  border-style: none;
`

const AutoLogin = styled.label`
  font-size: 12px;
  color: #8d8d8d;
  line-height: 3;
`

const LoginEnd = styled.div`
  text-align: center;
  font-size: 12px;
  .loginLine {
    a {
      color: black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .noUser {
    text-decoration: underline;
  }
`

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

@observer
class SignInModalBase extends Component {
  @observable loading

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      isSignInModalOpen: true
    }
    this.loading = false;
  }
 
  render() {
    const { email, password, error } = this.state;
    const { isOpen, close, moveToSignUp } = this.props;
    const isInvalid = password === '' || email === '';

    return(
      isOpen ? 
      <Modal>
        <LoginModal>
          <SpanClose onClick={() => close("close")} style={{cursor: 'pointer'}}>
            &times;
          </SpanClose>
          <ModalContents>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <h3 style={{margin: 5, fontSize: 24, marginBottom: 20}}>
                VARO SIGN IN
              </h3>
            </div>
            <LoginInput
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <LoginInput
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
            <LoginMid>
              <AutoLogin onClick={this.onClickFindIdPwd} onMouseDown style={{cursor: 'pointer'}}>
                비밀번호 찾기
              </AutoLogin>
            </LoginMid>
            <LoginBtn onClick={this.onSubmit} disabled={isInvalid}>
              로그인
            </LoginBtn>
            <LoginEnd>
              <div className="loginLine">
                회원이 아니신가요? <Link onClick={moveToSignUp}>회원가입</Link>
              </div>
              <div style={{height: 10}} />
              {/*<div className="noUser">비회원 주문 조회</div>*/}
            </LoginEnd>
            {error && <p style={{color: 'red'}}>{error}</p>}
          </ModalContents>
        </LoginModal>
      </Modal> : <div />
    )
  }

  openModal = () => {
    this.setState({ 
      email: "",
      password: "",
      error: {},
      isModalOpen: true
    });
  };

  closeModal = () => {
    this.setState({ isSignInModalOpen: false });
  };
 
  onSubmit = (event) => {
    const { email, password } = this.state;
    this.loading = true
    this.props.firebase
    .doSignInWithEmailAndPassword(email, password)
    .then(() => {
      this.props.close("close");
      this.setState({ 
        isSignInModalOpen: false,
        ...INITIAL_STATE 
      });
      this.loading = false;
    })
    .catch(error => {
      this.loading = false;
      if (error.code === 'auth/invalid-email') {
        this.setState({ error: "유효하지 않은 이메일입니다." })
      } else if (error.code === 'auth/user-disabled') {
        this.setState({ error: "사용이 막힌 유저입니다." })
      } else if (error.code === 'auth/user-not-found') {
        this.setState({ error: "일치하는 유저가 없습니다." })
      } else if (error.code === 'auth/wrong-password') {
        this.setState({ error: "유효하지 않은 비밀번호입니다." })
      } else {
        this.setState({ error: error.message });
      }
    });

    event.preventDefault();
  };

  onClickFindIdPwd = () => {
    this.props.close("findPassword")
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
}

const SignInModal = compose(
  withRouter,
  withFirebase,
)(SignInModalBase);

export default SignInModal;