import React, { Component } from 'react';
import { BrowserRouter as Router, NavLink, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import * as ROUTES from '../../../constants/routes';
import styled, { keyframes, css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { slideInDown, slideOutUp } from 'react-animations';

import { Grid } from '@material-ui/core';
import LoginButton from '../../ui/button/LoginButton'
import varoTypography from '../../../static/image/svg/typo_varo.svg';
import '../../../static/css/style.css';
import DehazeIcon from '@material-ui/icons/Dehaze';
import varo_typo from '../../../static/image/svg/varo_typo.svg'

import { Button, Collapse } from 'react-bootstrap';

const HEADER_SCROLL_TOP_OPACITY = 1.0;

const Wrapper = styled.div`
	position: fixed;
	width: 100vw;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  z-index: 999;
  font-family: IBMPlexSansKR-Regular;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100vw;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  z-index: 20;
`;

const MenuAreaWrapper = styled.div`
  z-index: 30;
  width: 100vw;
  padding-left: 2vw;
  padding-right: 2vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #FFFFFF;
`;

const MenuContentArea = styled.div`
  width: 100%;
  display: flex;
  flexDirection: row;
  justifyContent: flex-start;
`

const LOGO = styled.img`
  margin-bottom: 0;
  @media(min-width: 1300px){
    padding: 0 4%;
    height: 55px;
  }
  @media(max-width: 1300px){
    padding: 0 1%;
    height: 16px;
  }
`

const LogoContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  @media (min-width: 700px) {
    margin-top: -1px;
	}
`;

const NavText = styled.p`
  color: ${ props => props.theme.color.BLACK};
  font-family: IBMPlexSansKR-Medium;
  margin: 6px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
`;

class Header_Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      isWebMenuBarOpen: false,
      headerBarOpacity: HEADER_SCROLL_TOP_OPACITY,
      collapseID: "",
    };
    this.resize = this.resize.bind(this);
  }

  resize() {
    this.setState({ hidden: !(window.innerWidth >= 700) });
  }

  componentWillMount() { }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    window.onscroll = () => {
      const { headerBarOpacity } = this.state;
      if (headerBarOpacity <= HEADER_SCROLL_TOP_OPACITY && window.scrollY > 700) {
        this.setState({
            headerBarOpacity: 1.0,
        });
      } else if (headerBarOpacity >= 1.0 && window.scrollY <= 700) {
        this.setState({
            headerBarOpacity: HEADER_SCROLL_TOP_OPACITY,
        });
      }
    };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  _renderMobileMenuArea = () => {
    return(
      <MenuAreaWrapper> 
        <MenuContentArea style={{backgroundColor: this.MenuAreaBgColorSelector("HOME")}}>
          <NavText onClick={() => alert("준비중입니다.")}>
            홈 화면
          </NavText>
        </MenuContentArea>
        <MenuContentArea style={{backgroundColor: this.MenuAreaBgColorSelector("STORY")}}>
          <NavText onClick={() => alert("준비중입니다.")}>
            바로에 대해
          </NavText>
        </MenuContentArea>
        <MenuContentArea style={{backgroundColor: this.MenuAreaBgColorSelector("MENU")}}>
          <NavLink exact activeClassName="active" to="/order/select" style={{ textDecoration: 'none' }}>
            <NavText>
              바로밀키트 주문하기
            </NavText>
          </NavLink>
        </MenuContentArea>
        <MenuContentArea 
          style={{backgroundColor: this.MenuAreaBgColorSelector("LOGIN")}}>
          <NavLink exact activeClassName="active" to={this._isUserLoggedIn()? "/mobile/mypage" : "/mobile/sign_in"} style={{ textDecoration: 'none' }}>
            <NavText>
              {this._isUserLoggedIn()? "마이페이지" : "로그인"}
            </NavText>
          </NavLink>
        </MenuContentArea>
        <div style={{width: '50%', height: 1, backgroundColor: '#BBB', marginBottom: 10}} />
      </MenuAreaWrapper>
    )
  }

  MenuAreaBgColorSelector = (area) => {
    const { screen } = this.props;
    return (
      screen === area? '#EEE' : null
    );
  }

  render() {
    return (
      <Wrapper>
        <ContentWrapper>
          <NavLink exact activeClassName="active" to="/" style={{ textDecoration: 'none', alignSelf: 'center', justifySelf: 'center' }}>
            <LogoContainer>
              <LOGO src={varo_typo} /> 
            </LogoContainer>
          </NavLink>
          <DehazeIcon onClick={this._clickSidebarIcon} 
            style={{ fontSize: 20, position: 'absolute', right: 15, marginTop: 5, cursor: 'pointer' }} />
        </ContentWrapper>
        <Collapse in={this.state.isWebMenuBarOpen}>
          {this._renderMobileMenuArea()}
        </Collapse>
      </Wrapper>
    )
  }

  _clickSidebarIcon = () => {
    this.setState({ isWebMenuBarOpen: !this.state.isWebMenuBarOpen })
  }

  _clickUserIcon = () => {
    if (this._isUserLoggedIn()) {
      this.props.history.push(ROUTES.MY_PAGE);
    } else {
      this.setState({ isSignInModalOpen: true });
    }
  }

  _isUserLoggedIn = () => {
    return this.props.sessionStore.authUser
  }

  closeSignInModal = () => {
    this.setState({ isSignInModalOpen: false });
  }

  closeSignUpModal = () => {
    this.setState({ isSignUpModalOpen: false });
  }

  moveToSignUp = () => {
    this.setState({ 
      isSignInModalOpen: false,
      isSignUpModalOpen: true
    });
  }
}

export default compose(
  inject('sessionStore'),
  observer,
  withRouter
)(Header_Mobile);
//export default Header;
