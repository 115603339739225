import React, { Component } from 'react';
import Router, { withRouter } from 'next/router';
import Styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { computed, observable } from 'mobx';
import setAuthUser from '../../components/utils/auth';
import { checkUsernameAvailability, requireVerification } from '../../components/utils/id';
import { Input, Button } from '../../components/base'
import { validateEmail, validatePassword, validateUsername, getUsernameError } from "../../components/utils/validator";
import Typo_VARO from '../../static/image/svg/typo_varo.svg';
import LoginOptions from './login_options';
import '../../static/css/style.css';
import Header_Trans from '../../components/base/Header_Trans';
import AppColors from '../../theme/AppColors';

const SignUpWrapper = Styled.div`
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`; 

const Root = Styled.div`
  font-family: AppleSDGothicNeo-Light;
  font-size: 1.7143rem;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = Styled.div`
  min-height: calc(100vh - 15.1429rem);
  max-width: 25.7143rem;
  margin: 0 auto;
  margin-top: 0;
  padding: 0;
`;

const processColor = {
  0: {

  },
}

@inject('remoteFlagsStore')
@observer
class SignUpScreen extends Component {
  @computed
  get validEmail() {
    return this.state.email.value !== '' && validateEmail(this.state.email.value);
  }
  @computed
  get emailError() {
    return this.validEmail ? null : "올바르지 않은 이메일 형식입니다.";
  }
  @computed
  get validPassword() {
    return this.state.password.value !== '' && validatePassword(this.state.password.value,
        this.props.remoteFlagsStore.remoteFlags.loginMinPasswordLength);
  }
  @computed
  get passwordError() {
    return this.validPassword ? null : "비밀번호는 6글자 이상이어야 합니다.";
  }
  @computed
  get validPasswordConfirm() {
    return this.state.password.value === this.state.passwordConfirm.value;
  }
  @computed
  get passwordConfirmError() {
    return this.validPasswordConfirm ? null : "비밀번호가 일치하지 않습니다.";
  }
  @computed
  get validUsername() {
    return validateUsername(
        this.state.username.value,
        this.props.remoteFlagsStore.remoteFlags.loginMinUsernameLength,
        this.props.remoteFlagsStore.remoteFlags.loginMaxUsernameLength
      )
  }
  @computed
  get usernameError() {
    return getUsernameError(
        this.state.username.value,
        this.props.remoteFlagsStore.remoteFlags.loginMinUsernameLength,
        this.props.remoteFlagsStore.remoteFlags.loginMaxUsernameLength,
      );
  }
  @computed
  get valid() {
    return this.validEmail && this.validPassword && this.validPasswordConfirm &&
    this.validUsername && this.state.agreement.value;
  }

  @observable loading
  
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      signupError: null,
      resent: false,
      email: {
        value: '',
        touched: false
      },
      password: {
        value: '',
        touched: false
      },
      passwordConfirm: {
        value: '',
        touched: false
      },
      username: {
        value: '',
        touched: false
      },
      agreement: {
        value: false
      },
      emailSubmitError: null,
      passwordSubmitError: null,
      usernameSubmitError: null
    };
    this.loading = false;
  }
  
  bgcolor = (stepNumber) => {
    return this.state.currentStep < stepNumber ? '#000000' : '#FFD700'
  }

  textcolor = (stepNumber) => {
    return this.state.currentStep < stepNumber ? '#FFFFFF' : '#FFFFFF'
  }

  _renderProcessStepFigure = (stepNumber, stepString) => {
    return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
      <div style={{width: 40, height: 40, borderRadius: 20, position: 'absolute', backgroundColor: this.bgcolor(stepNumber)}} />
      {/*<div style={{width: 30, height: 30, borderRadius: 5, position: 'absolute', transform: 'rotate(45deg)', backgroundColor: this.bgcolor(stepNumber)}} />*/}
      <div style={{width: 40, height: 40, position: 'absolute', justifyContent: 'center', alignItems: 'center'}}>
        <p style={{color: this.textcolor(stepNumber), padding: 0, margin: 0, fontSize: 25}}>
          {stepNumber}
        </p>
      </div>
      <div style={{position: 'absolute', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', paddingTop: 40}}>
        <p>
          {stepString}
        </p>
      </div>
    </div>
    )
  }

  _renderBodyFirst = () => {
    const { email, password, passwordConfirm, username, agreement,
      emailSubmitError, passwordSubmitError, usernameSubmitError } = this.state
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', 
          margin: "0 auto 1.7143rem", padding: "0.7857rem 2.2857rem 2.8571rem" }}>
        <div style={{ paddingBottom: '2.2857rem', lineHeight: '1', width: '100%'}}>
          <label className="checkbox">
            <input type="checkbox" onChange={this.changeAgreement}
              checked={agreement.value}
              onFocus={this.touchAgreement}
            />
          </label>
          <span style={{ fontSize: 13, color: '#a2a2a2' }} >
            <a href="/intro/terms" style={{ color: '#a2a2a2', textDecoration: 'underline' }}>
              서비스 이용 약관
            </a>
            {"과 "}
            <a href="/intro/privacy" style={{ color: '#a2a2a2', textDecoration: 'underline' }}>
              개인정보처리방침
            </a>
            {" 동의"}
          </span>
        </div>
      </div>
    
    )
  }
  // step 1 : terms of service agreement
  // step 2 : id, password, nickname
  // step 3 : address and phone-number
  // step 4 : payment infomation

  render() {
    const { email, password, passwordConfirm, username, agreement,
      emailSubmitError, passwordSubmitError, usernameSubmitError } = this.state
    return (
      <Root className="index-page">
        <Header_Trans />
        <div style={{height: '10vh'}}/>
        <h1 style={{fontWeight: 500, margin: 0, padding: 0}}>
          WELCOME! VARO SIGN-UP
        </h1>
        <SignUpWrapper>
          <div style={{height: 70}} />
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {this._renderProcessStepFigure(1, '약관동의')}
            <div style={{width: 200, height: 1, backgroundColor: this.bgcolor(1), justifySelf: 'center', marginTop: 19}} />
            {this._renderProcessStepFigure(2, '아이디 설정')}
            <div style={{width: 200, height: 1, backgroundColor: this.bgcolor(2), justifySelf: 'center', marginTop: 19}} />
            {this._renderProcessStepFigure(3, '배송정보 입력')}
            <div style={{width: 200, height: 1, backgroundColor: this.bgcolor(3), justifySelf: 'center', marginTop: 19}} />
            {this._renderProcessStepFigure(4, '지불방법 등록')}
          </div>
        </SignUpWrapper>
        <div style={{height: 70}} />
        <h4 style={{fontWeight: 400, margin: 0, padding: 0}}>
          개인정보 보호와 이용약관에 동의해주세요.
        </h4>
        <h4 style={{fontWeight: 400, margin: 0, padding: 0}}>
          동의하지 않으면 회원가입을 할 수 없습니다.
        </h4>
        <div style={{height: 100}} />
        <div style={{width: '50vw', display: 'flex', flexDirection: 'column', alignSelf: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'flex-start'}}>
            <div style={{width: 120, height: 30, borderRadius: 3, backgroundColor: '#ffd700', borderStyle: 'solid', borderWidth: 0.25, borderColor: '#000000'}}>
              <p style={{padding: 0, margin: 0, fontSize: 20}}>
                Email
              </p>
            </div>
            <div style={{width: 50}} />
            <Input
              placeholder='your email'
              type="text"
              value={email.value}
              onChange={this.changeEmail}
              onFocus={this.touchEmail}
            />
            {(emailSubmitError || (email.touched && this.emailError)) && (
              <div className="error" style={{ textAlign: 'left' }}>
                {emailSubmitError ? emailSubmitError : this.emailError}
              </div>
          )}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'flex-start'}}>
            <div style={{width: 120, height: 30, borderRadius: 3, backgroundColor: '#ffd700', borderStyle: 'solid', borderWidth: 0.25, borderColor: '#000000'}}>
              <p style={{padding: 0, margin: 0, fontSize: 20}}>
                Password
              </p>
            </div>
            <div style={{width: 50}} />
            <Input
              placeholder='password'
              type="password"
              value={password.value}
              onChange={this.changePassword}
              onFocus={this.touchPassword}
            />
            {(passwordSubmitError || (password.touched && this.passwordError)) && (
              <div className="error" style={{ textAlign: 'left' }}>
                {passwordSubmitError ? passwordSubmitError : this.passwordError}
              </div>
            )}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'flex-start'}}>
            <div style={{width: 120, height: 30, borderRadius: 3, backgroundColor: '#ffd700', borderStyle: 'solid', borderWidth: 0.25, borderColor: '#000000'}}>
              <p style={{padding: 0, margin: 0, fontSize: 20}}>
                Confirm
              </p>
            </div>
            <div style={{width: 50}} />
            <Input
              placeholder='confirm password'
              type="password"
              value={passwordConfirm.value}
              onChange={this.changePasswordConfirm}
              onFocus={this.touchPasswordConfirm}
            />
            {passwordConfirm.touched && this.passwordConfirmError && (
              <div className="error">
                {this.passwordConfirmError}
              </div>
            )}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'flex-start'}}>
            <div style={{width: 120, height: 30, borderRadius: 3, backgroundColor: '#ffd700', borderStyle: 'solid', borderWidth: 0.25, borderColor: '#000000'}}>
              <p style={{padding: 0, margin: 0, fontSize: 20}}>
                Nickname
              </p>
            </div>
            <div style={{width: 50}} />
            <Input
              placeholder='nickname'
              type="text"
              value={username.value}
              onChange={this.changeUsername}
              onFocus={this.touchUsername}
            />
            {(usernameSubmitError || (username.touched && this.usernameError)) && (
              <div className="error">
                {usernameSubmitError ? usernameSubmitError : this.usernameError}
              </div>
            )}
          </div>
        </div>
        <div style={{height: '20vh'}} />
      </Root>
    );
  }

  renderBody = () => {
    const { email, password, passwordConfirm, username, agreement,
      emailSubmitError, passwordSubmitError, usernameSubmitError } = this.state
    return (
      <div className="signup" style={{ margin: '10rem 1.1429rem 7.0714rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{height: 100}} />
        <div className="input-wrapper">
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <div style={{width: 100, height: 30, borderRadius: 3, backgroundColor: '#ffd700'}}>
              <p>
                Email
              </p>
            </div>
            <Input
              placeholder='your email'
              type="text"
              value={email.value}
              onChange={this.changeEmail}
              onFocus={this.touchEmail}
            />
            {(emailSubmitError || (email.touched && this.emailError)) && (
              <div className="error" style={{ textAlign: 'left' }}>
                {emailSubmitError ? emailSubmitError : this.emailError}
              </div>
          )}
          </div>
          
          <Input
            placeholder='password'
            type="password"
            value={password.value}
            onChange={this.changePassword}
            onFocus={this.touchPassword}
          />
          {(passwordSubmitError || (password.touched && this.passwordError)) && (
            <div className="error" style={{ textAlign: 'left' }}>
              {passwordSubmitError ? passwordSubmitError : this.passwordError}
            </div>
          )}
          <Input
            placeholder='confirm password'
            type="password"
            value={passwordConfirm.value}
            onChange={this.changePasswordConfirm}
            onFocus={this.touchPasswordConfirm}
          />
          {passwordConfirm.touched && this.passwordConfirmError && (
            <div className="error">
              {this.passwordConfirmError}
            </div>
          )}
          <Input
            placeholder='nickname'
            type="text"
            value={username.value}
            onChange={this.changeUsername}
            onFocus={this.touchUsername}
          />
          {(usernameSubmitError || (username.touched && this.usernameError)) && (
            <div className="error">
              {usernameSubmitError ? usernameSubmitError : this.usernameError}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', 
          margin: "0 auto 1.7143rem", padding: "0.7857rem 2.2857rem 2.8571rem" }}>
          <div style={{ paddingBottom: '2.2857rem', lineHeight: '1', width: '100%'}}>
            <label className="checkbox">
              <input type="checkbox" onChange={this.changeAgreement}
                checked={agreement.value}
                onFocus={this.touchAgreement}
              />
            </label>
            <span style={{ fontSize: 13, color: '#a2a2a2' }} >
              <a href="/intro/terms" style={{ color: '#a2a2a2', textDecoration: 'underline' }}>
                서비스 이용 약관
              </a>
              {"과 "}
              <a href="/intro/privacy" style={{ color: '#a2a2a2', textDecoration: 'underline' }}>
                개인정보처리방침
              </a>
              {" 동의"}
            </span>
          </div>
          <Button className="signup green" onClick={this.createUser} style={{color: 'white' }}
            disabled={!this.valid || !this.props.remoteFlagsStore.remoteFlagsLoaded || this.loading} >
            가입하기
          </Button>
          <div style={{ marginTop: '3.5714rem', fontSize: '0.8571rem', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <span style={{marginLeft: '1.1429rem', cursor: 'pointer', color: '#854cff', fontSize: '0.8571rem', fontFamily: 'AppleSDGothicNeo-Regular'}}>
              이미 계정이 있으신가요? 로그인
            </span>
          </div>
        </div>
      </div>
    );
  }
/*
  createUser = async () => {
    const email = this.state.email.value
    const password = this.state.password.value
    const username = this.state.username.value
    const available = await checkUsernameAvailability(username)
    if (!available) {
      this.setState({ usernameSubmitError: 'The username is not available' })
      return
    }
    this.loading = true;
    try {
      const res = await auth.createUserWithEmailAndPassword(email, password)
      const user = res.user;
      await user.updateProfile({ displayName: username })
      await db.ref(`/user_sign_up/${user.uid}`)
        .update({ updatedAt: firebase.database.ServerValue.TIMESTAMP, username })
        .catch(console.log)
      await db.ref(`users/${user.uid}`).update({ uid: user.uid, username, email })
        .catch(console.log)
      //await setAuthUser(this.props.AuthStore, auth.currentUser)
      await firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .set({
              username: username || user.uid,
            })
            .catch(error => console.log(error));
      this.loading = false;
      Router.push('/home');
    } catch(error) {
      this.loading = false;
      if (error.code === 'auth/weak-password') {
        this.setState({ passwordSubmitError: error.message })
      } else {
        this.setState({ emailSubmitError: error.message })
      }
    }
  }
*/
  touchEmail = (e) => {
    this.setState({ email: {...this.state.email, touched: true} })
  }

  changeEmail = (e) => {
    this.setState({ email: {...this.state.email, value: e.target.value} })
    if (this.state.emailSubmitError) {
      this.setState({ emailSubmitError: null })
    }
  }

  touchPassword = (e) => {
    this.setState({ password: {...this.state.password, touched: true} })
  }

  changePassword = (e) => {
    this.setState({ password: {...this.state.password, value: e.target.value} })
    if (this.state.passwordSubmitError) {
      this.setState({ passwordSubmitError: null })
    }
  }

  touchPasswordConfirm = (e) => {
    this.setState({ passwordConfirm: {...this.state.passwordConfirm, touched: true} })
  }

  changePasswordConfirm = (e) => {
    this.setState({ passwordConfirm: {...this.state.passwordConfirm, value: e.target.value} })
  }

  touchUsername = (e) => {
    this.setState({ username: {...this.state.username, touched: true} })
  }

  changeUsername = (e) => {
    this.setState({ username: {...this.state.username, value: e.target.value} })
    if (this.state.usernameSubmitError) {
      this.setState({ usernameSubmitError: null })
    }
  }

  touchAgreement = (e) => {
    this.setState({ agreement: {...this.state.agreement, touched: true} })
  }

  changeAgreement = (e) => {
    this.setState({ agreement: {...this.state.agreement, value: e.target.checked} })
  }
}

export default SignUpScreen;