import React from "react";
import { Button } from 'reactstrap';
import Styled from 'styled-components';

const StyledButton = Styled(Button)`
  &:focus {
    outline: none;
  }
`;

export default class extends React.Component {

    static defaultProps = {
        supportStyle: () => ({})
    };
 
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false
        };

        this.buildStyle = this.buildStyle.bind(this);
        this.buildProps = this.buildProps.bind(this);
    }

    buildStyle() {
        const currentStyleState = {
            isHovered: this.state.isHovered
        };

        const style = {
            ...(this.props.style || {}),
            ...this.props.supportStyle(currentStyleState)
        };

        return style;
    }

    buildProps() {
        const { supportStyle, ...rest } = this.props;
        return {
            ...rest,
            style: this.buildStyle()
        };
    }

    render() {
        return <StyledButton 
            type='button'
            onMouseOver={() => this.setState({
                isHovered: true
            })}
            onMouseLeave={() => this.setState({
                isHovered: false
            })}
            {...this.buildProps()}
        />;
    }
}
