import React, { Component } from 'react';
import styled from 'styled-components';
import '../../static/css/style.css';
import StartButton from '../../components/ui/button/StartButton';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.payload});
  background-color: ${props => props.theme.color.VARO_GREEN};
`;

const MainText = styled.div`
  text-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  font-family: Poppins;
  font-size: 60px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

const SubText = styled.div`
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
`;

class HomeView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _handleSignUpPress = async (type) => {
    if (type === "email") {
      this.props.onSignUp();
    }
  }

  render() {
    return (
      <Wrapper>
        <div style={{ height: 160 }}/>
        <MainText>
          START ALL PLANTED MEAL
        </MainText>
        <div
          onTouchStart={this._onTouchStart} 
          onTouchMove={this._onTouchMove}
          onTouchEnd={this._onTouchEnd}>

        </div>
      </Wrapper>
    )
  }
}

export default HomeView;