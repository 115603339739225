import React, { Component } from 'react';
import styled from 'styled-components';
import Header from '../../components/base/Header';
import MenuDetailScreen from './menu_detail_screen';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media (max-width: 768px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
  }
`;

class MenuLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Wrapper>
        <MenuDetailScreen />
      </Wrapper>
    )
  }
}

export default MenuLayout;