import Icon_F5B076 from '../../../static/recipe_icons/Icon_F5B076.png' // 매콤 감자단호박볶음탕
//import Icon_EFE1A7 from '../../../static/recipe_icons/Icon_EFE1A7.png' // 만두전골
//import Icon_EAF88A from '../../../static/recipe_icons/Icon_EAF88A.png' // 코코넛 그린커리
//import Icon_1D4533 from '../../../static/recipe_icons/Icon_1D4533.png' // 비건 감바스
import Icon_7F463D from '../../../static/recipe_icons/Icon_7F463D.png' // 마파가지
import Icon_8ECEE0 from '../../../static/recipe_icons/Icon_8ECEE0.png' // 들깨크림파스타
import Icon_B7DCAB from '../../../static/recipe_icons/Icon_B7DCAB.png' // 감자탕
import Icon_E16441 from '../../../static/recipe_icons/Icon_E16441.png' // 유자 샐러드파스타
import Icon_EE8D85 from '../../../static/recipe_icons/Icon_EE8D85.png' // 보호 삼이탕
//import Icon_FFE1A0 from '../../../static/recipe_icons/Icon_FFE1A0.png' // 짬뽕
import Icon_765281 from '../../../static/recipe_icons/Icon_765281.png' // 모둠버섯전골
import Icon_EE7E20 from '../../../static/recipe_icons/Icon_EE7E20.png' // 순두부 찌개
import Icon_9D1E14 from '../../../static/recipe_icons/Icon_9D1E14.png' // 잡채 덮밥
import Icon_AD220B from '../../../static/recipe_icons/Icon_AD220B.png' // 궁중 떡볶이

export const MENU_INFO = {
  1: {
    1: {
      title: '만두전골',
      icon: 'Icon_EFE1A7',
      price: 7000,
      color_code: '#EFE1A7',
      info_source: 'static/image/Img_info_EE8D85'
    },
    2: {
      title: '보호 삼이탕',
      icon: 'Icon_EE8D85',
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    },
    3: {
      title: '매콤 단호박감자볶음탕',
      icon: 'Icon_F5B076',
      price: 7000,
      color_code: '#F5B076',
      info_source: 'static/image/Img_info_F5B076'
    }
  },
  2: {
    1: {
      title: '모둠버섯전골',
      icon: 'Icon_765281',
      price: 7000,
      color_code: '#765281',
      info_source: 'static/image/Img_info_765281'
    },
    2: {
      title: '만두전골',
      icon: 'Icon_EFE1A7',
      price: 7000,
      color_code: '#EFE1A7',
      info_source: 'static/image/Img_info_EFE1A7'
    },
    3: {
      title: '마파가지',
      icon: 'Icon_7F463D',
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    }
  },
  3: {
    1: {
      title: '모둠버섯전골',
      icon: 'Icon_765281',
      price: 7000,
      color_code: '#765281',
      info_source: 'static/image/Img_info_765281'
    },
    2: {
      title: "순두부찌개",
      icon: 'Icon_EE7E20',
      price: 7000,
      color_code: '#EE7E20',
      info_source: 'static/image/Img_info_EE7E20'
    },
    3: {
      title: '보호 삼이탕',
      icon: 'Icon_EE8D85',
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    },
    4: {
      title: "버섯들깨크림파스타",
      icon: 'Icon_8ECEE0',
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    }
  },
  4: {
    1: {
      title: '감자탕',
      icon: 'Icon_B7DCAB',
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    2: {
      title: "순두부찌개",
      icon: 'Icon_EE7E20',
      price: 7000,
      color_code: '#EE7E20',
      info_source: 'static/image/Img_info_EE7E20'
    },
    3: {
      title: '보호 삼이탕',
      icon: 'Icon_EE8D85',
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    },
    4: {
      title: "버섯들깨크림파스타",
      icon: 'Icon_8ECEE0',
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    }
  },
  5: {
    1: {
      title: "잡채덮밥",
      icon: 'Icon_9D1E14',
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    2: {
      title: "순두부찌개",
      icon: 'Icon_EE7E20',
      price: 7000,
      color_code: '#EE7E20',
      info_source: 'static/image/Img_info_EE7E20'
    },
    3: {
      title: '구수 시래기 감자탕',
      icon: 'Icon_B7DCAB',
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    4: {
      title: '보호 삼이탕',
      icon: 'Icon_EE8D85',
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  6: {
    1: {
      title: "납작 당면 잡채 덮밥",
      icon: 'Icon_9D1E14',
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    2: {
      title: '매콤 단호박감자볶음탕',
      icon: 'Icon_F5B076',
      price: 7000,
      color_code: '#F5B076',
      info_source: 'static/image/Img_info_F5B076'
    },
    3: {
      title: '마파가지',
      icon: 'Icon_7F463D',
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  7: {
    1: {
      title: '구수 시래기 감자탕',
      icon: Icon_B7DCAB,
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    2: {
      title: '모둠 버섯전골',
      icon: Icon_765281,
      price: 7000,
      color_code: '#765281',
      info_source: 'static/image/Img_info_765281'
    },
    3: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  8: {
    1: {
      title: '구수 시래기 감자탕',
      icon: Icon_B7DCAB,
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    2: {
      title: "순두부찌개",
      icon: Icon_EE7E20,
      price: 7000,
      color_code: '#EE7E20',
      info_source: 'static/image/Img_info_EE7E20'
    },
    3: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  9: {
    1: {
      title: '매콤 단호박감자볶음탕',
      icon: Icon_F5B076,
      price: 7000,
      color_code: '#F5B076',
      info_source: 'static/image/Img_info_F5B076'
    },
    2: {
      title: '마파가지',
      icon: Icon_7F463D,
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    },
    3: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  10: {
    1: {
      title: '구수 시래기 감자탕',
      icon: Icon_B7DCAB,
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    2: {
      title: '모둠버섯전골',
      icon: Icon_765281,
      price: 7000,
      color_code: '#765281',
      info_source: 'static/image/Img_info_765281'
    },
    3: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  11: {
    1: {
      title: "순두부찌개",
      icon: Icon_EE7E20,
      price: 7000,
      color_code: '#EE7E20',
      info_source: 'static/image/Img_info_EE7E20'
    },
    2: {
      title: '마파가지',
      icon: Icon_7F463D,
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    },
    3: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  12: {
    1: {
      title: "말린호박 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: '구수 시래기 감자탕',
      icon: Icon_B7DCAB,
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    3: {
      title: '모둠버섯전골',
      icon: Icon_765281,
      price: 7000,
      color_code: '#765281',
      info_source: 'static/image/Img_info_765281'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  13: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: '매콤 단호박감자볶음탕',
      icon: Icon_F5B076,
      price: 7000,
      color_code: '#F5B076',
      info_source: 'static/image/Img_info_F5B076'
    },
    3: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  14: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: '마파가지',
      icon: Icon_7F463D,
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    },
    3: {
      title: "순두부찌개",
      icon: Icon_EE7E20,
      price: 7000,
      color_code: '#EE7E20',
      info_source: 'static/image/Img_info_EE7E20'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  15: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    3: {
      title: '구수 시래기 감자탕',
      icon: Icon_B7DCAB,
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  16: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    3: {
      title: "순두부찌개",
      icon: Icon_EE7E20,
      price: 7000,
      color_code: '#EE7E20',
      info_source: 'static/image/Img_info_EE7E20'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  17: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: '매콤 단호박감자볶음탕',
      icon: Icon_F5B076,
      price: 7000,
      color_code: '#F5B076',
      info_source: 'static/image/Img_info_F5B076'
    },
    3: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  18: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: '매콤 단호박감자볶음탕',
      icon: Icon_F5B076,
      price: 7000,
      color_code: '#F5B076',
      info_source: 'static/image/Img_info_F5B076'
    },
    3: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  19: {
    1: {
      title: '구수 시래기 감자탕(2인분)',
      icon: Icon_B7DCAB,
      price: 7000,
      color_code: '#B7DCAB',
      info_source: 'static/image/Img_info_B7DCAB'
    },
    2: {
      title: '마파가지',
      icon: Icon_7F463D,
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    },
    3: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  20: {
    1: {
      title: '모둠 버섯전골',
      icon: Icon_765281,
      price: 7000,
      color_code: '#765281',
      info_source: 'static/image/Img_info_765281'
    },
    2: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    3: {
      title: "새콤달콤 유자 샐러드 파스타",
      icon: Icon_E16441,
      price: 7000,
      color_code: '#E16441',
      info_source: 'static/image/Img_info_E16441'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  21: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    3: {
      title: "새콤달콤 유자 샐러드 파스타",
      icon: Icon_E16441,
      price: 7000,
      color_code: '#E16441',
      info_source: 'static/image/Img_info_E16441'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  22: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    3: {
      title: "새콤달콤 유자 샐러드 파스타",
      icon: Icon_E16441,
      price: 7000,
      color_code: '#E16441',
      info_source: 'static/image/Img_info_E16441'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  23: {
    1: {
      title: '마파가지',
      icon: Icon_7F463D,
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    },
    2: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    3: {
      title: "새콤달콤 유자 샐러드 파스타",
      icon: Icon_E16441,
      price: 7000,
      color_code: '#E16441',
      info_source: 'static/image/Img_info_E16441'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  24: {
    1: {
      title: '마파가지',
      icon: Icon_7F463D,
      price: 7000,
      color_code: '#7F463D',
      info_source: 'static/image/Img_info_7F463D'
    },
    2: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    3: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  25: {
    1: {
      title: '매콤 단호박감자볶음탕',
      icon: Icon_F5B076,
      price: 7000,
      color_code: '#F5B076',
      info_source: 'static/image/Img_info_F5B076'
    },
    2: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    3: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  },
  26: {
    1: {
      title: "황금 궁중 떡볶이",
      icon: Icon_AD220B,
      price: 7000,
      color_code: '#AD220B',
      info_source: 'static/image/Img_info_AD220B'
    },
    2: {
      title: "버섯들깨크림파스타",
      icon: Icon_8ECEE0,
      price: 7000,
      color_code: '#8ECEE0',
      info_source: 'static/image/Img_info_8ECEE0'
    },
    3: {
      title: "납작 당면 잡채 덮밥",
      icon: Icon_9D1E14,
      price: 7000,
      color_code: '#9D1E14',
      info_source: 'static/image/Img_info_9D1E14'
    },
    4: {
      title: '보호 삼이탕',
      icon: Icon_EE8D85,
      price: 7000,
      color_code: '#EE8D85',
      info_source: 'static/image/Img_info_EFE1A7'
    }
  }
}