import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';

import {db, auth, functions, storage, firebase} from '../../firebase';
import { withFirebase } from '../../components/firebase';
import * as ROUTES from '../../constants/routes';

import Header from '../../components/base/Header';
import styled, { keyframes } from 'styled-components';
import AccountBox from './mypage/account_box';
import OrderInfoBox from './mypage/orderinfo_box';
import { MDBBtn } from 'mdbreact';

import Ico_user from '../../static/image/svg/Ico_user.svg';


const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const SidebarText = styled.p`
  margin: 0;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  @media(max-width: 1300px){
    font-size: 14px;
  }
`
const VaroText = styled.p`
  font-weight: 600;
  font-family: IBMPlexSansKR-Medium;
  margin: 0;
  padding: 0;
  color: #222;
  @media (min-width: 1300px) {
    font-size: 17px;
	}
  @media (max-width: 1300px) {
    font-size: 16px;
  }
`

@observer
class MyPageScreenBase extends Component {
  @observable loading

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      error: null,
      isPhoneModalOpen: false,
      isAddressModalOpen: false,

      address: "",
      zoneCode : "",
      fullAddress : "",
      isDaumPost : false,
      isRegister : false,
      register: [],
      addressPick: "",
      addressDatas: [],

      week1_data: null,
      week2_data: null,

      isOrdered: false,
      price: 0,
      menu1_order: 0,
      menu2_order: 0,
      menu3_order: 0,
      order_name: "",
      order_phone: "",
      order_zoneCode: "",
      order_address1: "",
      order_address2: "",
      deliveryRequest: "",
      isRequestReceipt: false,
      receiptPhone: "",
      orderStatus: 0,
      isOrderConfirmModalOpen: false,
      isOrderCancelModalOpen: false,
      tabSelected: 2,
    }
    this.loading = false;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    if (this.props.sessionStore.authUser.address) {
      this.setState({
        addressDatas: Object.keys(this.props.sessionStore.authUser.address)
      })
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
 
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { tabSelected, width } = this.state;
    return(
      <Wrapper>
        <Header style={{boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.1)'}}/>
        <div style={{display: 'flex', width: 200, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: '12vh', marginLeft: '10vw'}}>
          <h1 style={{fontWeight: '900'}}>
            MY PAGE
          </h1>
          <div style={{width: '100%', height: 1, backgroundColor: '#BBB', marginTop: 20, marginBottom: 20}} />
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <img src={Ico_user} style={{width: 30, marginRight: 10}} />
            <p style={{margin: 0, fontSize: 18}}>
              {this.props.sessionStore.authUser.username}
            </p>
          </div>
          <div style={{width: '100%', height: 1, backgroundColor: '#BBB', marginTop: 20, marginBottom: 20}} />
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <p style={{margin: 0, fontWeight: '800', fontSize: 18}}>
              나의 정보
            </p>
            <div style={{height: 10}} />
            <SidebarText onClick={() => this.setState({ tabSelected: 1 })}>사용자 정보</SidebarText>
            <div style={{height: 10}} />
            <SidebarText onClick={() => this.setState({ tabSelected: 2 })}>주문 정보</SidebarText>
            <div style={{height: 10}} />
            <SidebarText onClick={() => alert("varospirit@gmail.com 으로 문의해주시기 바랍니다.")}>회원 탈퇴</SidebarText>
          </div>
          <div style={{width: '100%', height: 1, backgroundColor: '#BBB', marginTop: 20, marginBottom: 20}} />
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <p style={{margin: 0, fontWeight: '800', fontSize: 18}}>
              나의 레시피 (준비중)
            </p>
            <div style={{height: 10}} />
            <SidebarText>레시피 평가</SidebarText>
            <div style={{height: 10}} />
            <SidebarText>레시피 요청</SidebarText>
            <div style={{height: 10}} />
            <SidebarText>나의 레시피 카드</SidebarText>
          </div>
          <div style={{width: '100%', height: 1, backgroundColor: '#BBB', marginTop: 20, marginBottom: 20}} />
          <div onClick={this._doSignOut}
            style={{display: 'flex', flexDirection: 'column', width: 120, height: 45, borderRadius: width>800?'2vw':'1vw', marginLeft: 40,
            justifyContent: 'center', backgroundColor: '#FFF', boxShadow: '3px 3px 10px 1px rgba(0,0,0,0.2)', cursor: 'pointer'}}>
            <VaroText style={{margin: 0, padding: 0, fontWeight: 500, textAlign: 'center', alignSelf: 'center', color: '#000'}}>
              {'로그아웃'}
            </VaroText>
          </div>
        </div>
        { tabSelected === 1? <AccountBox /> : null }
        { tabSelected === 2? <OrderInfoBox /> : null }
      </Wrapper>
    )
  }

  _closeOrderModal = () => {
    this.setState({ isOrderConfirmModalOpen: false })
  }

  _openOrderConfirmModal = () => {
    this.setState({ isOrderConfirmModalOpen: true })
  }

  _openOrderCancelModal = () => {
    this.setState({ isOrderCancelModalOpen: true })
  }

  _closeOrderCancelModal = () => {
    this.setState({ isOrderCancelModalOpen: false })
  }

  _openAddressModal = () => {
    if (this.state.addressDatas.length >= 3) {
      alert("3개를 초과하여 주소를 등록할 수 없습니다.")
    } else {
      this.setState({ isAddressModalOpen: true })
    }
  }

  _doSignOut = () => {
    this.props.firebase.doSignOut()
    this.props.history.push(ROUTES.HOME);
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  refreshPage = () => {
    window.location.reload();
  }
}

const MyPageScreen2 = compose(
  withRouter,
  withFirebase,
  inject('sessionStore'),
  observer,
)(MyPageScreenBase);

export default MyPageScreen2;
 