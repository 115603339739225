import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../../components/firebase';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { init, send } from 'emailjs-com';

import Header from '../../components/base/Header';
import Footer from '../../components/base/Footer';
import ScrollToTop from '../../components/base/ScrollToTop';
import Header_Mobile from '../../components/base/mobile/header_mobile';
import * as ROUTES from '../../../src/constants/routes';

import { FcIdea, FcPanorama, FcCollaboration, FcEnteringHeavenAlive } from 'react-icons/fc';
import AppColors from '../../theme/AppColors';

const Wrapper = styled.div`
  width: 100%;
  height: auto !important;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: NotoSansCJKkr-Regular;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const TitleContentWrapper = styled.div`
  width: 100%;
  height: 30vh;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: NotoSansCJKkr-Regular;
`

const ServiceContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font-family: NotoSansCJKkr-Regular;
`
// background-color: #ecf1f3;
const ServiceCardRow = styled.div`
  width: calc(100% - 80px);
  max-width: 1100px;
  min-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ServiceCard = styled.div`
  position: relative;
  width: calc(50% - 20px);
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #FFF;
  font-family: NotoSansCJKkr-Regular;
  background-image: ${props => props.gradient};
`

const ServiceCardIconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const DetailText = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
  position: absolute;
  bottom: 15px;
  right: 30px;
`

// background-color: #ecf1f3;
const TitleText = styled.p`
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 10px;
`

const TitleContentText = styled.p`
  width: 50%;
  min-width: 700px;
  font-size: 23px;
  font-weight: 600;
  color: #666;
  margin-bottom: 0;
  font-family: NotoSansKR;
`

const ContactContentWrapper = styled.div`
  width: 60%;
  min-width: 700px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: NotoSansCJKkr-Regular;
`

const ExplainBox = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 40px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 2px solid #BBB;
`

const InputBox = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  display: flex;
  border: 1.5px solid #BBB;
  border-radius: 3px;
`

const TextAreaBox = styled.textarea`
  width: 100%;
  height: 200px;
  margin-bottom: 5px;
  display: flex;
  border: 1.5px solid #BBB;
  border-radius: 3px;
`

const Btn = styled.div`
  width: 200px;
  height: 50px;
  margin: 30px;
  cursor: pointer;
  background-color: ${AppColors.NAVY_BLUE};
  color: white;
  border-radius: 5px;
  font-size: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const ServiceCardTitleText = styled.p`
  margin: 10px;
  font-size: 28px;
  font-weight: bold;
  color: #FFF;
  font-family: NotoSansKR;
`

const ServiceCardContentText = styled.p`
  margin: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500px;
`

class ContactPageBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      isOpen: true,
      name: '',
      telephone: '',
      email: '',
      message: '',
    };
  }

  componentDidMount() {
    init(process.env.REACT_APP_EmailJS_USER_ID);
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const isValid = this.state.name && this.state.email && this.state.telephone && this.state.message;
    
    return (
      <Wrapper>
        <ScrollToTop />
        <Header style={{position: 'absolute'}} />
        <ContentWrapper>
          <div style={{height: 100}} />
          <TitleContentWrapper>
            <TitleText>
              제휴 및 비즈니스 제안
            </TitleText>
            <TitleContentText>
              바로는 더 나은 세상을 만들고 파트너사와 동반성장 할 수 있는 제안들부터 여러 가지 서비스 협업 제안을 기다리고 있습니다.
            </TitleContentText>
          </TitleContentWrapper>
          <ServiceContentWrapper>
            <ServiceCardRow>
              <ServiceCard gradient={"radial-gradient( circle farthest-corner at 10% 20%,  rgba(97,186,255,1) 0%, rgba(166,239,253,1) 90.1% )"}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <ServiceCardIconCircle>
                  <FcIdea size={'20px'} />
                </ServiceCardIconCircle>
                <ServiceCardTitleText>비건 레시피 개발</ServiceCardTitleText>
                </div>
                <ServiceCardContentText>
                  바로 R&D 팀은 다양하고 맛있는 비건 레시피를 개발하고 컨설팅합니다.
                  (한식 뿐만 아니라 중식, 타이, 양식 등 다양한 레시피를 개발 및 컨설팅)
                </ServiceCardContentText>
                <DetailText>
                  {"Detail >>"}
                </DetailText>
              </ServiceCard>
              <ServiceCard gradient={"radial-gradient( circle farthest-corner at 10% 20%,  rgba(171,102,255,1) 0%, rgba(116,182,247,1) 90% )"}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ServiceCardIconCircle>
                    <FcCollaboration size={'20px'} />
                  </ServiceCardIconCircle>
                  <ServiceCardTitleText>식당 콜라보레이션</ServiceCardTitleText>
                </div>
                <ServiceCardContentText>
                  이미 판매 중이거나 신규 출시하는 메뉴를 밀키트로 개발하여 판매합니다.
                  (재료 선별부터 패키징, 유통까지 바로 R&D팀의 세심한 코칭)
                </ServiceCardContentText>
                <DetailText>
                  {"Detail >>"}
                </DetailText>
              </ServiceCard>
            </ServiceCardRow>

            <ServiceCardRow>
              <ServiceCard gradient={"linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)"}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ServiceCardIconCircle>
                    <FcPanorama size={'20px'} />
                  </ServiceCardIconCircle>
                  <ServiceCardTitleText>교육 콘텐츠 제공</ServiceCardTitleText>
                </div>
                <ServiceCardContentText>
                  CEO 직강, 비거니즘 강의 및 교육 자료를 제공합니다.
                  (기후위기, 채식 관련 수업 자료 및 학생용 활동지가 포함된 교육용 밀키트 판매 예정)
                </ServiceCardContentText>
                <DetailText>
                  {"Detail >>"}
                </DetailText>
              </ServiceCard>
              <ServiceCard gradient={"linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%)"}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ServiceCardIconCircle>
                    <FcEnteringHeavenAlive size={'20px'} />
                  </ServiceCardIconCircle>
                  <ServiceCardTitleText>공동 이벤트 진행</ServiceCardTitleText>
                </div>
                <ServiceCardContentText>
                  비건 쿠킹클래스, 세미나, 워크숍, 간담회 등 다양한 비거니즘 관련 이벤트를 기획 및 진행합니다.
                </ServiceCardContentText>
                <DetailText>
                  {"Detail >>"}
                </DetailText>
              </ServiceCard>
            </ServiceCardRow>
          </ServiceContentWrapper>

          <ContactContentWrapper>
            <ExplainBox>
              <h1 style={{fontSize: 40, fontWeight: 700}}>
                바로와 함께
              </h1>
              <h5 style={{fontSize: 20, fontWeight: 500}}>
                모두를 위한 일상을 만들어 보세요
              </h5>
            </ExplainBox>
            <InputBox name='name' placeholder='이름' value={this.state.name} onChange={this.onChange}/>
            <InputBox name='telephone' placeholder='연락처(전화번호)' value={this.state.telephone} onChange={this.onChange}/>
            <InputBox name='email' placeholder='이메일' value={this.state.email} onChange={this.onChange}/>
            <TextAreaBox name='message' placeholder='문의 내용' value={this.state.message} onChange={this.onChange}/>
            <Btn onClick={() => this.sendEmail()} style={{ opacity : isValid ? 1 : 0.5 }} disabled={!isValid}> 
              문의 넣기
            </Btn>
          </ContactContentWrapper>
          
        </ContentWrapper>
        <Footer />
      </Wrapper>
    );
  }

  sendEmail = () => {
    const { name, telephone, email, message } = this.state;

    send(process.env.REACT_APP_EmailJS_SERVICE_ID, process.env.REACT_APP_EmailJS_TEMPLATE_ID, { name : name, telephone : telephone, email : email, message : message })
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text)
      alert('문의해주셔서 감사합니다. 홈 화면으로 이동합니다.')
      this.props.history.push('/')
    }, (error) => {
      console.log('FAILED...', error)
    })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }
}

const ContactPage = compose(
  withRouter,
  withFirebase,
  inject('sessionStore'),
  inject('orderStore'),
  observer,
)(ContactPageBase);

export default ContactPage;