import { observable, action } from 'mobx';
import { persist, create } from 'mobx-persist';
import localForage from 'localforage';

class OrderStore {
  rootStore = null;

  @persist('object') @observable weeklyOrderCheck = {
    2021: [],
    2022: [3, 5]
  };
  @persist('map') @observable weeklyOrderInfo = observable.map({});
  @persist('list') @observable mylist = [];
  @persist
  @observable
  num = 4;

  constructor() {
    const hydrate = create({
      storage: localForage, // AsyncStorage for RN
      jsonify: true // true for RN or saving objects
    });
    hydrate('order', this)
    .then(() =>{
      console.log("HYDRATE FINISH")
    })
    .catch(e => {
      console.log("HYDRATE ERROR", e)
    })
  }

  setRootStore(rootStore) {
    this.rootStore = rootStore;
    return this;
  }

  @action
  setMyList = (num) => {
    this.mylist.push(num)
  }

  @action
  setNumber = (num) => {
    this.num = num
  }

  @action
  _clearOrderStoreInfo = () => {
    console.log('clearing existing user-order data ....')
    this.weeklyOrderCheck.clear();
    this.weeklyOrderInfo.clear();
  }

  @action
  _loadUserOrderInfo = uid => {
    console.log(`loading user-specific persistent order data for user ${uid} ...`)
    this._loadWeeklyOrderInfo(uid);
    console.log('loading finished');
  }

  @action
  setWeeklyOrderCheck = (year, weekNumber) => {
    if ( !Object.keys(this.weeklyOrderCheck).includes(year) ){
      this.weeklyOrderCheck[year] = [weekNumber]
    } else {
      this.weeklyOrderCheck[year] = [
        weekNumber,
        ...this.weekOrderCheck[year]
      ]
    }
  }

  @action
  setAuthUser = authUser => {
    this.authUser = authUser;
  }

  getWeeklyOrderCheck 
}

export default OrderStore;