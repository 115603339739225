export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(pw, minLength) {
  return pw.length && pw.length >= minLength;
}

export function validateUsername(name, minLength, maxLength) {
  if (!name || nonAlphanumeric.test(name) || leadingNumeric.test(name) ||
      name.length < minLength || name.length > maxLength) {
    return false;
  }
  return true;
}

export function getUsernameError(name, minLength, maxLength) {
  let error = null;
  if (!name) {
    error = "닉네임을 입력해주세요."
  } else if (nonAlphanumeric.test(name)) {
    error = "닉네임은 숫자와 영문만 가능합니다."
  } else if (leadingNumeric.test(name)) {
    error = "닉네임은 숫자로 시작할 수 없습니다."
  } else if (name.length < minLength) {
    error = "닉네임은 5글자 이상이어야 합니다."
  } else if (name.length > maxLength) {
    error = "닉네임은 13글자 이하이어야 합니다."
  }
  return error;
}

export const nonAlphanumeric = /[^A-Za-z0-9_]/

export const leadingNumeric = /^\d/
