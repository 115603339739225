import { action, computed, observable } from 'mobx';
import Firebase from '../components/firebase/firebase';

class RemoteFlagsStore {
  @observable remoteFlags = {};
  @observable remoteFlagsLoaded = false;
  
  constructor(rootStore) {
    this.rootStore = rootStore;
    //this.loadRemoteFlags();
  }

  /*
  @action
  loadRemoteFlags() {
    this.props.firebase.db.ref('/remote_flags/').once('value')
    .then(snap => {
      if (!snap.exists()) return;
      this.remoteFlags = snap.val();
      this.remoteFlagsLoaded = true;
    })
  }
  */
}

export default RemoteFlagsStore;
